//todo: find a way to import tailwind colors
const colors = {
  yellow: "#f3c250",
  yellow200: "#F8DA96",
  mental: "#5f7beb",
  mentalDark: "#1A3FD1",
  mentalLight: "#DEE3F7",
  physical: "#f5536c",
  physicalDark: "#F21245",
  physicalLight: "#FFD6DB",
  social: "#69cdab",
  socialDark: "#44C196",
  socialLight: "#DDF4E8",
  navy: "#232c3f",
  navyLight: "#3E4E6F",
  success: "#44c196",
  warning: "#eb8a19",
  danger: "#eb1919",
  danger400: "#ED3131",
  grey: "#64748b",
  blueGrey: "#5A71A0",
  beige: "#FFFDF8",
  darkerBeige: "#f1f5f9",
  whiteTransparent75: "rgba(255,255,255,0.75)",
  whiteTransparent20: "rgba(255,255,255,0.2)",
};

export default colors;
