import React from "react";
import { ReactComponent as Loader } from "../../assets/icons/loading.svg";
import colors from "../../theme/colors";

//todo remove asap

type RoundedButtonType = {
  disabled?: boolean;
  label?: string;
  onPress?: () => void;
  className?: string;
  isLoading?: boolean;
  icon?: string;
  buttonColor?: string;
  borderColor?: string;
  textColor?: string;
  width?: string;
  children?: React.ReactNode;
};

const RoundedButton = ({
  disabled,
  label,
  onPress,
  className,
  isLoading,
  icon,
  buttonColor,
  borderColor,
  textColor,
  width = "w-full",
  children,
}: RoundedButtonType) => {
  return (
    <button
      onClick={!disabled ? onPress : undefined}
      disabled={disabled}
      className={`${width} ${buttonColor ? buttonColor : "bg-custom-lightNavy"}
       ${
         disabled
           ? "cursor-not-allowed opacity-70"
           : "opacity-1 cursor-pointer hover:opacity-80"
       } flex items-center justify-center rounded-full py-3 transition-all ${className}`}
      style={{
        backgroundColor: buttonColor ? buttonColor : colors.navyLight,
        borderWidth: borderColor ? "2px" : undefined,
        borderColor: borderColor ? borderColor : undefined,
      }}
    >
      {!isLoading ? (
        <div className="flex flex-wrap items-center">
          {icon}
          <p style={{ color: textColor ? textColor : colors.beige }}>
            {label}
            {children}
          </p>
        </div>
      ) : (
        <Loader
          className="animate-spin"
          fill="#FFFDF8"
          width={18}
          height={18}
        />
      )}
    </button>
  );
};

export default RoundedButton;
