import React, { useState, useRef } from "react";
import Rodal from "rodal";
import { fetchDailyEvaluation, postAnswer } from "../../services/form";
import {
  IconByDimension,
  getIconByDimension,
  getNameByDimension,
} from "../../utils/dimensions";
import colors from "../../theme/colors";
import DailyFormResults from "../dailyFormResults/DailyFormResults";
import { useMutation, useQuery } from "@tanstack/react-query";
import { LoaderInButton, PrimaryButton } from "../buttons";
import { useCurrentUser } from "../../services/me";

function CustomRodal({
  children,
  visible,
  onClose,
}: {
  children: React.ReactNode;
  visible: boolean;
  onClose: () => void;
}) {
  return (
    <Rodal
      height={430}
      width={550}
      customStyles={{ padding: 0, borderRadius: 25 }}
      showCloseButton={false}
      visible={visible}
      onClose={onClose}
    >
      {children}
    </Rodal>
  );
}

type DailyFormModalProps = {
  setIsFetchingForm: (value: boolean) => void;
  setIsDailyAvailable: (value: boolean) => void;
  isOpen: boolean;
  onClose: () => void;
};

const DailyFormModal = ({
  setIsDailyAvailable,
  isOpen,
  onClose,
}: DailyFormModalProps) => {
  const sliderRef = useRef<HTMLInputElement>(null);
  const dimensions = ["physical", "mental", "social"];
  const [questionIndex, setQuestionIndex] = useState(0);
  const [sliderIndex, setSliderIndex] = useState(1);
  const [isEnded, setIsEnded] = useState(false);

  const { data: evaluation } = useQuery({
    queryFn: () => fetchDailyEvaluation(),
  });
  const { refetch } = useCurrentUser();
  const { isLoading: isAnswerSending, mutate } = useMutation({
    mutationFn: ({ value }: { value: number }) => {
      if (!evaluation) throw new Error("Pas d'evaluation");
      const formToSend = {
        formId: evaluation.id,
        userFormId: evaluation.user_wellbeing_form_id,
        questionId: evaluation.questions[questionIndex].id,
        questionPosition: evaluation.questions[questionIndex].position,
        answer: {
          value,
          start_initial_score:
            questionIndex === evaluation.questions.length - 1
              ? true
              : undefined,
        },
      };

      return postAnswer(formToSend);
    },

    onSuccess: () => {
      if (!evaluation) throw new Error("Pas d'evaluation");
      if (questionIndex >= evaluation.questions.length - 1) {
        refetch();
        setIsEnded(true);
        setIsDailyAvailable(false);
      } else {
        sliderRef?.current?.style?.setProperty?.("--value", "1");
        sliderRef?.current?.style?.setProperty?.("--min", "1");
        sliderRef?.current?.style?.setProperty?.("--max", "7");
        setQuestionIndex(questionIndex + 1);
        setSliderIndex(1);
      }
    },
  });

  if (isEnded)
    return (
      <CustomRodal visible={isOpen} onClose={onClose}>
        <DailyFormResults onClose={onClose} />
      </CustomRodal>
    );

  if (!evaluation) return null;

  return (
    <Rodal
      height={430}
      width={550}
      customStyles={{ padding: 0, borderRadius: 25 }}
      showCloseButton={false}
      visible={isOpen}
      onClose={onClose}
    >
      <div>
        <div
          className={`bg-custom-${evaluation.questions[questionIndex].dimension.slug} flex items-center justify-center rounded-t-3xl`}
          style={{ height: 100, transition: "background-color 0.5s" }}
        >
          <div className="flex flex-wrap items-center">
            {dimensions.map((dimension, index) => {
              if (
                dimension === evaluation.questions[questionIndex].dimension.slug
              ) {
                return (
                  <div
                    className={`mx-2 rounded-full px-2 py-1.5 pr-4 bg-custom-${dimension}Dark`}
                  >
                    <div className="flex flex-wrap items-center">
                      <div
                        className={`p-2 bg-custom-${dimension} rounded-full`}
                      >
                        <IconByDimension
                          color={colors.beige}
                          size={18}
                          dimension={dimension}
                        />
                      </div>
                      <p className=" text-custom-beige ml-3">
                        {getNameByDimension(dimension).toUpperCase()}
                      </p>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="mx-2 rounded-full p-2"
                    style={{
                      border: `4px solid ${
                        index < questionIndex
                          ? colors.whiteTransparent75
                          : colors.whiteTransparent20
                      }`,
                    }}
                  >
                    {getIconByDimension(dimension, 16, colors.beige)}
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="bg-custom-beige rounded-b-3xl p-8 pb-4">
          <p className="text-center text-xl ">
            {evaluation.questions[questionIndex].text}
          </p>
          <div
            style={{ maxWidth: 380 }}
            className="mt-6 ml-auto mr-auto flex flex-wrap items-center justify-between"
          >
            {Array.apply(null, Array(7)).map((e, index) => (
              <p
                className={`${
                  sliderIndex === index + 1
                    ? "text-lg font-semibold text-custom-yellow"
                    : "text-xs text-custom-grey"
                }`}
              >
                {index + 1}
              </p>
            ))}
          </div>
          <div className="relative mt-4 mb-4 flex justify-center">
            <input
              ref={sliderRef}
              onChange={(e) => {
                const el = e.target;
                el.style.setProperty("--value", el.value);
                el.style.setProperty("--min", "1");
                el.style.setProperty("--max", "7");
                setSliderIndex(parseInt(e.target.value));
              }}
              defaultValue={1}
              type="range"
              min={1}
              max={7}
              value={sliderIndex}
              className="slider"
              id="myRange"
            />
          </div>
          <div
            className="mb-6 ml-auto mr-auto flex flex-wrap justify-between"
            style={{ maxWidth: 400 }}
          >
            <p className="text-xs text-custom-grey">Pas du tout d'accord</p>
            <p className="text-xs text-custom-grey">Tout à fait d'accord</p>
          </div>
          <div className="flex flex-grow" />
          <div style={{ width: 250 }} className="ml-auto mr-auto mt-8">
            <PrimaryButton
              className="mt-12"
              type="submit"
              disabled={isAnswerSending}
              onClick={() => mutate({ value: sliderIndex })}
            >
              {isAnswerSending ? <LoaderInButton /> : "Question suivante"}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Rodal>
  );
};

export default DailyFormModal;
