import cover1 from "../assets/png/wiltord1.png";
import cover2 from "../assets/png/corbel1.png";
import cover3 from "../assets/png/six1.jpg";
import cover4 from "../assets/png/sibert1.jpg";
import cover5 from "../assets/png/danet1.jpg";
import cover6 from "../assets/png/paupelain1.png";
import cover7 from "../assets/png/glachet1.png";
import cover8 from "../assets/png/hazart1.png";
import cover9 from "../assets/png/materne1.jpg";
import cover10 from "../assets/png/sibert2.png";
import cover11 from "../assets/png/six2.png";
import cover12 from "../assets/png/danet2.jpg";
import cover13 from "../assets/png/paupelain2.png";
import cover14 from "../assets/png/flandrin1.png";
import lidwine from "../assets/png/Masterclass_Lidwine.png";
import question_equilibre from "../assets/png/question_equilibre.png";

const replays = [
  {
    id: 17,
    cover: lidwine,
    video: "https://www.youtube.com/embed/ekq3caZMkkU",
    title: "POSITIVE ATTITUDE : toutes les clés pour gagner en sérénité",
    duration: 2476,
    dimension: "mental",
    subdimension: "bonheur",
    author: "Lidwine Réveillon",
    description: `Voir la vie en rose, mettre des paillettes dans nos yeux, c'est beaucoup plus facile à dire qu'à faire.
Lidwine Réveillon, facilitatrice en transformation dont la spécialité est l'optimisme va partager ses méthodes pour cultiver ta pensée positive. Grâce à ses conseils et ses exercices de mise en situation, tu pourras faire face à tes pensées négatives limitantes et automatiques.
Si tu apprends à changer ces mécanismes, tu seras en mesure de mieux gérer les défis du quotidien : conflits, imprévus, stress, frustration...
Elle est pas belle la vie ?`,
  },
  {
    id: 16,
    cover: question_equilibre,
    video: "https://www.youtube.com/embed/ql0rGHDj4Ow",
    title: "QUESTION D'ÉQUILIBRE : gérer ses vies pro et perso",
    duration: 2848,
    dimension: "mental",
    subdimension: "équilibre",
    author: "Thomas Dias",
    description: `Jongler entre sa vie professionnelle et sa vie personnelle est une compétence précieuse et difficile à maîtriser.
Thomas DIAS, psychologue, vous donnera les clés pour comprendre vos besoins, premier pas vers l'équilibre.
Au programme : prise de conscience sur la perception, l'auto-évaluation, la gestion et l'influence sociale sur vos habitudes de vie.`,
  },
  {
    id: 1,
    cover: cover1,
    video: "https://www.youtube.com/embed/LighM2zxzLQ",
    title:
      "Pourquoi développer son intelligence émotionnelle et comment faire ? 🌱",
    duration: 1801,
    dimension: "mental",
    subdimension: "émotions",
    author: "Fabienne Wiltord",
    description:
      "Retrouvez Fabienne Wiltord, Happy coach et conférencière à l'occasion de ce live exclusif.\n" +
      "\n" +
      "Elle vous livre :\n" +
      "\n" +
      "👉 les 5 dimensions de l'intelligence émotionnelle selon le psychologue et journaliste américain Daniel Goleman.\n" +
      "\n" +
      "👉 et surtout 5 clés concrètes pour booster votre intelligence émotionnelle.\n" +
      "\n" +
      "Un live plein de pep's et de bonne humeur dans lequel vous accédez aux clés pour apprendre à développer votre intelligence émotionnelle afin de mieux gérer vos émotions au quotidien 🌱\n" +
      "\n" +
      "Moha et Fabienne collaborent pour faciliter votre accès au mieux-être jour après jour ☀️",
  },
  {
    id: 2,
    cover: cover2,
    video: "https://www.youtube.com/embed/vlqpwzGAUo4",
    title: "Apprendre à refuser pour mieux s’affirmer 💪🏼",
    duration: 1800,
    dimension: "social",
    subdimension: "relationnel",
    author: "Estelle Corbel",
    description:
      "Retrouvez Estelle Corbel, coach professionnelle, coach de vie et conférencière lors de ce nouveau live by Moha !\n" +
      "\n" +
      "Pourquoi refuser pour mieux s'affirmer ? Quand décider de refuser ou non ? Comment refuser ?\n" +
      "\n" +
      "Estelle vous explique comment transformer vos croyances limitantes et les concepts de bases de la communication non-violente. 💪🏼\n" +
      "\n" +
      "Moha et Estelle collaborent pour faciliter votre accès au mieux-être jour après jour ☀️\n",
  },
  {
    id: 3,
    cover: cover3,
    video: "https://www.youtube.com/embed/5NIQvQKgVFw",
    title: "Les plantes médicinales, un soutien à notre hygiène de vie 🌿",
    duration: 1800,
    dimension: "physical",
    subdimension: "plantes",
    author: "Perrine Six",
    description:
      "Il est temps d'intégrer de bonnes routines dans ta vie !\n" +
      "\n" +
      "Viens rencontrer Perrine Six, naturopathe et iridologue pour connaître les bienfaits des plantes médicinales 🌿\n" +
      "\n" +
      "Au programme :\n" +
      "\n" +
      "- En quoi les plantes peuvent-elles nous aider à prendre notre santé en main ?\n" +
      "- Qu’est-ce que l'herboristerie ?\n" +
      "- Sous quelles formes peut-on prendre les plantes médicinales ?\n" +
      "- Et au niveau de la santé ? Que peuvent nous apporter les plantes ?\n" +
      "- 3 propriétés médicinales de 3 plantes comestibles\n" +
      "- 3 plantes pour un sommeil profond\n" +
      "\n" +
      "Moha et Perrine collaborent pour faciliter ton accès au mieux-être jour après jour ☀️",
  },
  {
    id: 4,
    cover: cover4,
    video: "https://www.youtube.com/embed/svH-AP8KruQ",
    title: "Être inspirant·e pour soi et aux yeux des autres",
    duration: 1800,
    dimension: "mental",
    subdimension: "mental",
    author: "Mareeva Sibert",
    description:
      "Il est temps de prendre les choses en main et d'inspirer autour de soi !\n" +
      "\n" +
      "C'est justement le sujet que te propose d'aborder Mareeva Sibert, coach professionnelle, lors de ce live exclusif.\n" +
      "\n" +
      "Au programme :\n" +
      "\n" +
      "- Qu'est-ce qu'une personne inspirante ?\n" +
      "- Les 3 types de reconnaissance : l'estime de soi, la confiance en soi et l'image de soi\n" +
      "- Collaborer avec les 5 grandes peurs\n" +
      "\n" +
      "Moha et Mareeva collaborent pour faciliter ton accès au mieux-être jour après jour ☀️",
  },
  {
    id: 5,
    cover: cover5,
    video: "https://www.youtube.com/embed/IKMi4kOfPbU",
    title: "Comprendre et apprivoiser le syndrome de l'imposteur",
    duration: 2700,
    dimension: "mental",
    subdimension: "estime de soi",
    author: "Julie Danet",
    description:
      "Tu doutes en permanence de la légitimité de tes succès et détestes être au centre de l'attention ?\n" +
      "\n" +
      "Manque de confiance en soi, complexes et dévalorisation... sont des caractéristiques du syndrome de l'imposteur 👤\n" +
      "\n" +
      "Nous te proposons un live exclusif avec Julie Danet, coach et formatrice pour comprendre d'où il provient et enfin parvenir à le dégommer !\n" +
      "\n" +
      "Au programme, les 6 piliers pour enfin en venir à bout :\n" +
      "\n" +
      "✅ définir son pourquoi\n" +
      "\n" +
      "✅ apprendre à remettre de la valeur\n" +
      "\n" +
      "✅ apprendre à se connaître\n" +
      "\n" +
      "✅ travailler son état d'esprit\n" +
      "\n" +
      "✅ apprendre à lâcher prise\n" +
      "\n" +
      "✅ mettre en valeur son unicité\n" +
      "\n" +
      "Julie et Moha collaborent pour faciliter ton accès au mieux-être jour après jour ☀️",
  },
  {
    id: 6,
    cover: cover6,
    video: "https://www.youtube.com/embed/xs26tGz9gBc",
    title: "L’importance de la préparation mentale au quotidien 🧠",
    duration: 2700,
    dimension: "physical",
    subdimension: "préparation mentale",
    author: "Nicolas Paupelain",
    description:
      "Usain Bolt aurait-il battu son record du monde du 100m, s’il n’avait pas eu confiance en lui ? \n" +
      "\n" +
      "On dit souvent qu’une victoire, c’est 80% dans la tête et 20% dans notre corps. La force du mental est insoupçonnée et peut être utilisée pour nous, les travailleurs du quotidien pour apprendre à avoir confiance en soi, mieux gérer ses émotions et son stress.",
  },
  {
    id: 7,
    cover: cover7,
    video: "https://www.youtube.com/embed/c-th69M2JRQ",
    title: "La communication non-violente: un outil à portée de tous",
    duration: 1500,
    dimension: "mental",
    subdimension: "communication",
    author: "Ophélie Glachet",
    description:
      "Il nous arrive parfois de critiquer et d’émettre des jugements, sur soi ou sur les autres. Sans en être toujours conscients ces paroles représentent une certaine forme de violence, envers nous et ceux qui nous entourent. Être en lien avec les autres s’apparente à un terrain miné où nos paroles peuvent avoir des conséquences émotionnelles que nous n’avions pas anticipé. Pour éviter cela, la communication non-violente (CNV) te propose d’appliquer quatre principes afin de communiquer de manière “consciente”.",
  },
  {
    id: 8,
    cover: cover8,
    video: "https://www.youtube.com/embed/X8WJvmT_ybU",
    title: "J’apprends à gérer mon stress avec la cohérence cardiaque",
    duration: 1800,
    dimension: "mental",
    subdimension: "stress",
    author: "Juliette Hazart",
    description:
      "Ce n’est plus à prouver, le stress prolongé est source de mal-être physique et psychique ... 🤯\n" +
      "\n" +
      "Pourtant, sais-tu que nous avons besoin d’une certaine dose de stress pour rester performant et créatif ?\n" +
      "\n" +
      "Et oui, tout est question d’équilibre !\n" +
      "\n" +
      "Juliette Hazart, docteure, praticienne pleine conscience et coach en cohérence cardiaque va t’apprendre durant ce live à utiliser la cohérence cardiaque pour réguler ton stress.\n" +
      "\n" +
      "Ensemble nous allons : \n" +
      "\n" +
      "✅ Parcourir l’histoire de l’adaptation au stress\n" +
      "\n" +
      "✅ Analyser le fonctionnement de la cohérence cardiaque \n" +
      "\n" +
      "✅ Intégrer les bénéfices sur ton plan physique, mental et relationnel \n" +
      "\n" +
      "✅ Apprendre à utiliser la cohérence cardiaque rapidement \n" +
      "\n" +
      "Prêt à prendre en main ton stress négatif ?\n" +
      "\n" +
      "Juliette et Moha collaborent pour faciliter ton accès au mieux-être jour après jour ☀️",
  },
  {
    id: 9,
    cover: cover9,
    video: "https://www.youtube.com/embed/t8LQx_djDz4",
    title: "J’apprends à gérer les changements dans un monde en transition",
    duration: 1800,
    dimension: "social",
    subdimension: "agilité",
    author: "Jimmy Materne",
    description:
      "Nous sommes en pleine polycrise ! 😨\n" +
      "\n" +
      "Covid, guerre en Ukraine, rupture des matières premières, réchauffement climatique... On ne sait plus où donner de la tête !\n" +
      "\n" +
      "Tous ces sujets ne sont pas évidents à gérer, mais rassure-toi, ce n’est pas une fatalité et toi aussi, tu peux apprendre à gérer leur impact sur ton quotidien. D’un point de vue plus théorique, nous vivons dans un monde que l’on appelle VUCA. Mais qu’est-ce que cela signifie ? 🧐\n" +
      "\n" +
      "Le terme VUCA correspond en français à : volatile, incertain, complexe et ambigu. Ce qui veut simplement dire que le monde change tellement vite qu’il faut sans cesse s’adapter et faire preuve de flexibilité afin d’accueillir les changements radicaux pour lesquels nous n’étions pas préparés.\n" +
      "\n" +
      "Au programme de ce live : comment et pourquoi il faut embrasser ce nouveau monde ?\n" +
      "\n" +
      "Nous te proposons de rencontrer Jimmy Materne, scrum master et coach agile, afin de recevoir de nouvelles perspectives d’actions sur les changements à faire et à venir.\n" +
      "\n" +
      "Alors, prêt à embrasser le changement ?\n" +
      "\n" +
      "Jimmy et Moha collaborent pour faciliter ton accès au mieux-être jour après jour ☀️",
  },
  {
    id: 10,
    cover: cover10,
    video: "https://www.youtube.com/embed/3iBhET68R-A",
    title: "Je passe à l’action et j’évolue vers ma nouvelle vie !",
    duration: 1800,
    dimension: "mental",
    subdimension: "engagement",
    author: "Mareeva Sibert",
    description:
      "Sais-tu ce qui t’empêche de passer cette dernière marche qui te donnera confiance en toi ? 🧐\n" +
      "Si tu te sens bien dans ta vie, que tout va bien et que tu es heureux mais qu’il te manque un truc ... tu ne sais pas quoi mais il te manque LE truc. \n" +
      "\n" +
      "Cette chose nécessaire pour atteindre la vie de tes rêves et évoluer. \n" +
      "\n" +
      "Mareeva Sibert, coach professionnelle, t’expliquera lors de ce live pourquoi tu stagnes et comment passer à l’action pour que les choses changent et évoluent enfin ✊🏼 \n" +
      "\n" +
      "Au programme pour atteindre ton épanouissement :\n" +
      "\n" +
      "✅ Les nouvelles habitudes qui te font peur et t’empêchent de passer à l’action \n" +
      "\n" +
      "✅ T’enfermer dans un schéma négatif ou rebondir pour évoluer : c’est à toi de choisir ! \n" +
      "\n" +
      "✅ Engageons le passage à l’action \n" +
      "\n" +
      "Tu es prêt à prendre les choses en main ? \n" +
      "\n" +
      "Mareeva et Moha collaborent pour faciliter ton accès au mieux-être jour après jour ☀️",
  },
  {
    id: 11,
    cover: cover11,
    video: "https://www.youtube.com/embed/PRGnWh-tKTA",
    title: "J’adopte une vie anti-stress grâce à mon alimentation",
    duration: 1920,
    dimension: "physical",
    subdimension: "alimentation",
    author: "Perrine Six",
    description:
      "Les effets néfastes du stress sur notre organisme sont aujourd’hui bien connus.\n" +
      "\n" +
      "Toutefois, il reste difficile de s’en débarrasser en s’occupant. Comment réussir à caler du temps dans nos planning surchargés pour prendre un peu plus soin de soi-même ou en prenant des vacances. ? \n" +
      "\n" +
      "En cause ici : ton rythme de vie trop intense, tes différentes obligations et nos modes de vie modernes…\n" +
      "\n" +
      "Heureusement, ton alimentation peut t’aider à mieux gérer ton stress ! 🍏 \n" +
      "\n" +
      "Une certaine hygiène de vie permet d’évacuer les tensions et de diminuer les effets du stress. En choisissant les bons aliments et en favorisant les conditions de santé, nous pouvons reprendre le pouvoir sur ces épisodes difficiles à gérer ! \n" +
      "\n" +
      "Perrine et Moha collaborent pour faciliter ton accès au mieux-être jour après jour ☀️",
  },
  {
    id: 12,
    cover: cover12,
    video: "https://www.youtube.com/embed/Xl8stLERBj4",
    title: "Je prends ma vie en main grâce aux 5 accords toltèques",
    duration: 1800,
    dimension: "mental",
    subdimension: "estime de soi",
    author: "Julie Danet",
    description:
      "Il est temps de mettre en place les 5 accords Toltèques dans ta vie 🌟 \n" +
      "\n" +
      "Mais pour commencer, sais-tu ce qu’ils sont et ce qu’ils représentent ? \n" +
      "\n" +
      "Plus qu’une philosophie, ils sont des principes qui nous invitent à nous libérer ! Ces principes simples sont des règles de vie visant à nous exempter de croyances adoptées dès l’enfance et qui sont parfois à l’origine de nos souffrances. \n" +
      "\n" +
      "Voici les 5 accords Toltèques : \n" +
      "\n" +
      '🔥 "Que votre parole soit impeccable" \n' +
      "\n" +
      '🔥 "Quoi qu’il arrive n’en faites pas une affaire personnelle". \n' +
      "\n" +
      '🔥 "Ne faites pas de supposition" \n' +
      "\n" +
      '🔥 "Faites toujours de votre mieux" \n' +
      "\n" +
      '🔥 "Soyez sceptiques, mais apprenez à écouter". \n' +
      "\n" +
      "Au programme de ce live : comment appliquer facilement ces accords dans nos vies ? \n" +
      "\n" +
      "Prêt à embrayer le changement ? \n" +
      "\n" +
      "Julie et Moha collaborent pour faciliter ton accès au mieux-être jour après jour ☀️",
  },

  {
    id: 13,
    cover: cover13,
    video: "https://www.youtube.com/embed/3QEKgUml1lQ",
    title: "Je prends soin de mon corps en travaillant ma posture",
    duration: 1800,
    dimension: "physical",
    subdimension: "activité physique",
    author: "Nicolas Paupelain",
    description:
      "Mauvaises habitudes, mauvaise utilisation des équipements de bureau ou mauvais équipements tout court, ... 🥴 \n" +
      "\n" +
      "Que de facteurs qui entrainent une mauvaise posture quotidienne et provoquent des tensions musculaires, maux de dos ou encore un manque de mobilité articulaire. \n" +
      "\n" +
      "Il est temps de prendre une pause ! ✋🏼 \n" +
      "\n" +
      "Retrouvez Nicolas Paupelain, notre partenaire préparateur physique et mental, pour un live actif où nous allons prendre le temps de délier tout ça grâce à de petits exercices simples. \n" +
      "\n" +
      "On se lance ensemble ? \n" +
      "\n" +
      "Nicolas et Moha collaborent pour faciliter ton accès au mieux-être jour après jour ☀️ \n",
  },

  {
    id: 14,
    cover: cover14,
    video: "https://www.youtube.com/embed/hX2QKFaE8C8",
    title: "J’apprends à dompter mes envies alimentaires",
    duration: 1800,
    dimension: "physical",
    subdimension: "alimentation",
    author: "Coline Flandrin",
    description:
      "Il arrive à tout le monde d’avoir des compulsions alimentaires et elles peuvent avoir elles-mêmes plusieurs causes : stress, fatigue, mal-être, … 😵‍💫\n" +
      "\n" +
      "En effet, nous sommes tous confronter à certaines émotions au cours de la journée, et forcément aussi à la nourriture, conçue pour stimuler le sentiment de sécurité.  \n" +
      "\n" +
      "Mais alors, comment gérer nos prises alimentaires ? Comment ne pas se laisser influencer et gérer nos apports énergétiques au quotidien ? \n" +
      "\n" +
      "Coline Flandrin, diététicienne, va aborder avec toi lors de ce live exclusif cette thématique qui te touche forcément, afin de t’aider dans ta démarche. \n" +
      "\n" +
      "Coline et Moha collaborent pour faciliter ton accès au mieux-être jour après jour ☀️  \n",
  },
];

export default replays;
