import React, { useEffect, useRef } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import { IconByArticleType } from "../utils/articles";
import { getContentByIdLight } from "../services/library";
import ContentPart from "../components/content-part/ContentPart";
import ReactHlsPlayer from "react-hls-player";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as AngleLeft } from "../assets/icons/angle-left.svg";
import ReactGA from "react-ga";
import ResponsiveHeader from "../components/responsive-header/ResponsiveHeader";
import { useQuery } from "@tanstack/react-query";

const Content = () => {
  const playerRef = useRef<HTMLVideoElement | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const { data: content, isLoading } = useQuery({
    queryKey: ["ContentByIdLightQuery", id],
    queryFn: () => getContentByIdLight(id),
    refetchInterval: false,
  });

  return (
    <>
      <div className="flex md:hidden">
        <ResponsiveHeader />
      </div>
      <div className="flex h-full flex-wrap">
        <div className="hidden md:flex">
          <Sidebar />
        </div>
        {!isLoading && content && (
          <div
            className="mx-auto flex-grow p-8 md:pl-2"
            style={{ flexBasis: 0, maxWidth: 700 }}
          >
            <div className="mb-8 inline-block">
              <div
                onClick={goBack}
                className="flex cursor-pointer flex-wrap items-center justify-center p-2 transition-all hover:opacity-80"
              >
                <AngleLeft className="mr-3" height={14} />
                <p className=" text-xl">Retour</p>
              </div>
            </div>
            <img
              alt={"Cover"}
              className="mx-auto mb-12 h-64 w-full rounded-t-3xl object-cover"
              src={content.cover}
            />
            <div className="mx-auto px-3" style={{ maxWidth: 700 }}>
              <h1 className=" text-center text-2xl font-bold leading-loose">
                {content.title}
              </h1>
              <p className=" mt-2 text-center leading-loose text-custom-grey">
                {content.description}
              </p>
              <div className="mt-4 flex flex-wrap items-center justify-center">
                {content.sub_dimensions && content.dimensions && (
                  <div
                    className={`flex flex-wrap items-center bg-custom-${content.dimensions.slug} mr-3 rounded-lg px-2 py-1`}
                  >
                    <p className="text-xs text-custom-beige">
                      {content.sub_dimensions?.name?.toLowerCase() ?? ""}
                    </p>
                  </div>
                )}
                <IconByArticleType type={content.content_type} size={14} />
                <p className="ml-1 text-custom-grey">
                  {typeof content.time_to_read_in_seconds === "number"
                    ? Math.ceil(content.time_to_read_in_seconds / 60)
                    : "?"}{" "}
                  min
                </p>
              </div>
            </div>
            <div
              className={`${
                content.content_type === "article" ? "mt-16" : "mt-6"
              } mx-auto`}
            >
              {content.mux_playback_url &&
                content.content_type !== "article" && (
                  <div>
                    <ReactHlsPlayer
                      src={content.mux_playback_url}
                      playerRef={playerRef}
                      autoPlay={false}
                      controls={true}
                      width="100%"
                      height={"auto"}
                      className={
                        content.content_type === "podcast" ? "h-16" : ""
                      }
                    />
                  </div>
                )}
              {content &&
                content.content_parts &&
                content.content_parts
                  .sort((a, b) => {
                    return a.position - b.position;
                  })
                  .map((e) => {
                    return (
                      <ContentPart
                        contentPart={e}
                        dimensionSlug={content.dimensions.slug}
                      />
                    );
                  })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Content;
