import React from "react";
import { ReactComponent as AngleRight } from "../../assets/icons/angle-right.svg";

const SeeMoreArrow = () => {
  return (
    <div className="flex flex-wrap items-center justify-center p-2 transition-all hover:opacity-80">
      <p className="">Voir plus</p>
      <AngleRight style={{ marginTop: 3 }} className="ml-2" height={12} />
    </div>
  );
};

export default SeeMoreArrow;
