import React, { useEffect, useState, ReactNode } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import { Link, useSearchParams } from "react-router-dom";
import CardContent from "../components/card-article/CardContent";
import CardSkeletons from "../components/card-skeletons/CardSkeletons";
import { getIconByDimension } from "../utils/dimensions";
import colors from "../theme/colors";
import ReactGA from "react-ga";
import ResponsiveHeader from "../components/responsive-header/ResponsiveHeader";
import { useQuery } from "@tanstack/react-query";
import { listContents } from "../services/library";

const ContentLibrary = () => {
  const [searchParams] = useSearchParams();

  const [tab, setTab] = useState(
    searchParams.get("dimension") ? searchParams.get("dimension") : "all"
  );

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const setNewTab = (tabPressed: string) => {
    setTab(tabPressed);
  };

  const { data: contents } = useQuery({
    queryKey: ["listContentsQuery", tab],
    queryFn: () => listContents(tab),
    refetchInterval: false,
  });

  return (
    <>
      <div className="flex md:hidden">
        <ResponsiveHeader />
      </div>
      <div className="flex h-full flex-wrap">
        <div className="hidden md:flex">
          <Sidebar />
        </div>
        <div className="flex-grow p-8 md:pl-2" style={{ flexBasis: 0 }}>
          <div>
            <div className="flex items-center">
              <h1 className=" flex-grow text-2xl">
                La bibliothèque bien-être par Moha
              </h1>
            </div>
            <h1 className="text-md flex-grow text-custom-grey">
              Retrouve tous les contenus en libre accès qui te permettent de
              prendre soin de toi au quotidien
            </h1>
            <div className="mt-6 mb-8 flex flex-wrap items-center">
              <LibraryTab
                isSelected={tab === "all"}
                onPress={() => setNewTab("all")}
                label="Tous"
                className="mr-4 bg-custom-yellow"
              />
              <LibraryTab
                icon={getIconByDimension("physical", 15, colors.beige)}
                isSelected={tab === "physical"}
                onPress={() => setNewTab("physical")}
                label="Physique"
                className="mr-4 bg-custom-physical"
              />
              <LibraryTab
                icon={getIconByDimension("mental", 15, colors.beige)}
                isSelected={tab === "mental"}
                onPress={() => setNewTab("mental")}
                label="Mental"
                className="mr-4 bg-custom-mental"
              />
              <LibraryTab
                icon={getIconByDimension("social", 15, colors.beige)}
                isSelected={tab === "social"}
                onPress={() => setNewTab("social")}
                label="Relationnel"
                className="bg-custom-social"
              />
            </div>
            <div className="2xl-grid:cols-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
              <DisplayContents contents={contents} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

type LibraryTabProps = {
  className: string;
  label: string;
  onPress?: () => void;
  isSelected: boolean;
  icon?: ReactNode;
};

function LibraryTab({
  className,
  label,
  onPress,
  isSelected,
  icon,
}: LibraryTabProps) {
  return (
    <div
      onClick={onPress}
      className={`inline-block cursor-pointer rounded-xl px-5 py-2 transition-all hover:opacity-100 ${
        isSelected ? "opacity-100" : "opacity-50"
      } ${className}`}
    >
      <div className="flex flex-wrap items-center">
        {icon}
        <p className={`text-custom-beige  ${icon ? "ml-2" : ""}`}>{label}</p>
      </div>
    </div>
  );
}

function DisplayContents({
  contents,
}: {
  contents?: Awaited<ReturnType<typeof listContents>>;
}) {
  if (!contents || contents.length < 1) return <CardSkeletons count={20} />;

  return (
    <>
      {contents.map((content, i) => (
        <Link key={`contentList${i}`} to={`/content/${content.id}`}>
          <CardContent content={content} />
        </Link>
      ))}
    </>
  );
}

export default ContentLibrary;
