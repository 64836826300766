import React from "react";
import { ReactComponent as LogoO } from "../assets/svg/logo-o.svg";
import { PasswordInput, TextInput } from "../components/input/Input";
import { loginByEmail, passwordRegex } from "../services/login";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import colors from "../theme/colors";
import LoginShape from "../assets/png/login-shape.png";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { LoaderInButton, PrimaryButton } from "../components/buttons";

function Login() {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ email, password }: { email: string; password: string }) => {
      if (!email?.includes("@")) {
        throw new Error("email");
      }
      if (!passwordRegex.test(password)) {
        throw new Error("password");
      }

      return loginByEmail({
        email,
        password,
      });
    },

    onSuccess: ({ access_token, refresh_token, first_name }) => {
      localStorage.setItem("moha-at", access_token);
      localStorage.setItem("moha-rt", refresh_token);

      toast.success(`Bienvenue, ${first_name} ! ☀️`);
      navigate("/home");
    },
    onError: ({ mohaType }) => {
      if (mohaType === "email")
        return toast.error(`Renseigne ton adresse e-mail pour te connecter`);
      if (mohaType === "password")
        return toast.error(
          `Ton mot de passe doit contenir au moins 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial️`
        );
      toast.error(
        `Les identifiants renseignés ne sont pas associés à un compte existant. Vérifie le mail et le mot de passe indiqué️`
      );
    },
  });

  return (
    <div className="relative flex h-screen w-screen flex-wrap items-center justify-center">
      <div className="flex w-full items-center justify-center xl:w-1/2">
        <div style={{ maxWidth: 450 }} className="z-20">
          <LogoO width={60} fill={colors.navy} className="relative mx-auto" />
          <div className="relative mt-6 mb-12">
            <h1 className="mb-2  text-center text-2xl">Bienvenue sur Moha</h1>
            <p className="text-center">
              Entre tes identifiants pour accéder à ta plateforme bien-être
            </p>
          </div>
          <form
            onSubmit={handleSubmit((form) => {
              mutate(form);
            })}
          >
            <Controller
              name="email"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextInput
                  className="mb-6"
                  placeholder="karine@moha.com"
                  label={"Adresse e-mail"}
                  type="email"
                  required
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <PasswordInput
                  placeholder="********"
                  label="Mot de passe"
                  required
                  {...field}
                />
              )}
            />
            <div className="mt-1 flex items-end justify-end">
              <p
                onClick={() => navigate("/forgotpassword")}
                className="cursor-pointer transition-all hover:opacity-70"
              >
                Mot de passe oublié ?
              </p>
            </div>
            <div className="ml-auto mr-auto  flex content-center justify-center px-3">
              <PrimaryButton
                className="mt-12"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? <LoaderInButton /> : "Se connecter"}
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
      <div className="hidden w-1/2 xl:block">
        <img
          alt="Shape"
          className="absolute right-0 top-0 h-screen"
          src={LoginShape}
        />
      </div>
    </div>
  );
}

export default Login;
