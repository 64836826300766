import { ReactComponent as ArrowRight } from "../../assets/icons/button-arrow-right.svg";
import { CircleButton } from "./Button";

export function ArrowRightCircleButton(
  props: Parameters<typeof CircleButton>[0]
) {
  return (
    <CircleButton {...props}>
      <ArrowRight width={24} height={20} stroke="#FFFFFF" fill="none" />
    </CircleButton>
  );
}
