import React from "react";

type ContentPartProps = {
  contentPart: {
    content_part_type?: string;
    text_content?: string;
    picture_url?: string;
  };
  dimensionSlug?: string;
};

const ContentPart = ({ contentPart, dimensionSlug }: ContentPartProps) => {
  if (contentPart.content_part_type === "paragraph") {
    return (
      <p className="leading-loose text-custom-grey">
        {contentPart.text_content?.split("\n")?.map((e) => {
          return e.length > 0 ? <p>{e}</p> : <br />;
        })}
      </p>
    );
  }
  if (contentPart.content_part_type === "title") {
    return (
      <h2 className="mt-8 mb-4  text-xl font-semibold leading-loose">
        {contentPart.text_content}
      </h2>
    );
  }
  if (contentPart.content_part_type === "quote") {
    return (
      <div className="relative my-10 flex flex-wrap items-center">
        <div
          className={`bg-custom-${
            dimensionSlug ? dimensionSlug : "physical"
          } absolute h-full w-2 py-4 opacity-50`}
        />
        <p
          className={`text-xl leading-loose text-custom-${
            dimensionSlug ? dimensionSlug : "physical"
          } physical ml-8 flex-grow`}
          style={{ flexBasis: 0 }}
        >
          〝{contentPart.text_content}〞
        </p>
      </div>
    );
  }
  if (contentPart.content_part_type === "picture") {
    return (
      <div className="mt-10 mb-10">
        <img alt={contentPart.text_content} src={contentPart.picture_url} />
      </div>
    );
  }

  return null;
};

export default ContentPart;
