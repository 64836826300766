import React, { ReactNode, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  useForm,
  Controller,
  useFieldArray,
  ControllerRenderProps,
  FieldValues,
  Path,
  UseFormSetValue,
} from "react-hook-form";
import TextAreaAutoSize from "react-textarea-autosize";
import * as Select from "@radix-ui/react-select";
import { articleTypeOptions, IconByArticleType } from "../../utils/articles";
import { GoBackLayout } from "../../components/layout/GoBackLayout";
import {
  AdminContentType,
  getAdminContentById,
  getAdminThemes,
  updateAdminContent,
} from "../../services/adminContent";
import RoundedButton from "../../components/rounded-button/RoundedButton";
import ReactPlayer from "react-player";

import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

export function AdminContentUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: content,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["AdminContentByIdQuery", id],
    queryFn: () => getAdminContentById(id),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      data.cover_from_file = undefined;
      data.content_parts = data.content_parts?.sort((a, b) => {
        return a.position ? a.position - (b.position ?? 0) : b.position ?? 0;
      });
      contentForm.reset(data);
    },
  });

  const contentForm = useForm({
    defaultValues: content,
  });

  const [contentPartToDelete, setContentPartToDelete] = useState(
    new Set<number>()
  );

  const { isLoading: isUpdating, mutate: updateContent } = useMutation({
    mutationFn: () =>
      updateAdminContent({
        newContent: contentForm.getValues(),
        content_parts_to_delete: [...contentPartToDelete],
      }),
    onSuccess: (result) => {
      if (typeof result.content_id === "number") {
        navigate(`/admin/content/update/${result.content_id}`);
      } else {
        refetch();
      }
    },
  });

  if (isLoading || !content) return <GoBackLayout />;

  return (
    <GoBackLayout>
      <ContentMainValuesForm {...contentForm} content={content} />
      <ContentPartsForm
        {...contentForm}
        content={content}
        contentPartToDelete={contentPartToDelete}
        setContentPartToDelete={setContentPartToDelete}
      />
      <div className="ml-auto mr-auto" style={{ width: 225 }}>
        <RoundedButton
          isLoading={isLoading}
          className="mt-12"
          label={
            isUpdating
              ? "En cours de modification"
              : "Enregistrer les modifications"
          }
          onPress={updateContent}
          disabled={isUpdating}
        />
      </div>
    </GoBackLayout>
  );
}

type CustomSelectType = {
  children: ReactNode;
  className?: string;
  value: string | undefined;
  onValueChange: (value: string) => void;
  currentValue?: ReactNode;
};
function CustomSelect({
  children,
  className,
  value,
  onValueChange,
  currentValue = value,
}: CustomSelectType) {
  return (
    <Select.Root value={value} onValueChange={onValueChange}>
      <Select.Trigger className={className}>
        <Select.Value aria-label={value}>{currentValue}</Select.Value>
      </Select.Trigger>
      <Select.Portal className="bg-gray-50">
        <Select.Content>
          <Select.Viewport>{children}</Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}

function countWordsInText(text: string) {
  return text.split(" ").length;
}

function ContentMainValuesForm({
  control,
  register,
  setValue,
  getValues,
  content,
}: ReturnType<typeof useForm<AdminContentType>> & {
  content: AdminContentType;
}) {
  const [videoDuration, setDuration] = useState(0);

  function countWords() {
    const description = getValues("description");
    const description_count = description ? countWordsInText(description) : 0;
    const content_parts = getValues("content_parts");

    const content_parts_count =
      content_parts?.reduce(
        (count, content_part) =>
          count +
          (content_part.content_part_type !== "picture" &&
          content_part.text_content
            ? countWordsInText(content_part.text_content)
            : 0),
        0
      ) ?? 0;

    const time_to_read_in_seconds = Math.floor(
      videoDuration + ((description_count + content_parts_count) * 60) / 275
    );

    setValue("time_to_read_in_seconds", time_to_read_in_seconds);
  }

  const availableStatus = [
    { text: "En Cours de Redaction", textColor: "text-custom-yellow" },
    { text: "Prêt", textColor: "text-custom-mental" },
  ];

  const [themesAsObject, setThemesAsObect] = useState<{
    [id: string]: Awaited<ReturnType<typeof getAdminThemes>>[0];
  }>({});

  const { data: themes } = useQuery({
    queryKey: ["AdminThemesQuery"],
    queryFn: () => getAdminThemes(),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (themes) => {
      const themesAsObject: {
        [id: string]: Awaited<ReturnType<typeof getAdminThemes>>[0];
      } = {};

      themes.forEach((theme) => (themesAsObject["" + theme.id] = theme));

      setThemesAsObect(themesAsObject);
    },
  });
  return (
    <>
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <CustomSelect
            value={field.value?.toString()}
            onValueChange={(value) => field.onChange(parseInt(value))}
            currentValue={availableStatus[field.value ?? 0].text}
            className={`${
              availableStatus[field.value ?? 0].textColor
            } ml-20 text-2xl`}
          >
            {availableStatus.map(({ text, textColor }, i) => (
              <Select.Item key={`articleTypeOptions${i}`} value={i.toString()}>
                <Select.ItemText>
                  <p className={`${textColor} text-xl`}>{text}</p>
                </Select.ItemText>
              </Select.Item>
            ))}
          </CustomSelect>
        )}
      />
      <div className="flex flex-row justify-between">
        <div>Editeur : {content.last_edited_by_user?.first_name}</div>
        <div>
          Derniere edition :{" "}
          {content.updated_at
            ? moment(content.updated_at).format("D MMMM")
            : "??"}
        </div>
        <div>
          Derniere publication :{" "}
          {content.last_publish_date
            ? moment(content.last_publish_date).format("D MMMM")
            : "??"}
        </div>
      </div>
      <CustomImagePicker
        name="cover"
        control={control}
        alt="Cover"
        imgClassName="mx-auto h-64 w-full rounded-t-3xl object-cover"
        onChangeText="Changer la cover"
        registerResult={register("cover_from_file")}
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) {
            setValue("cover_from_file", file);
            setValue("cover", URL.createObjectURL(file));
          }
        }}
        onCancel={() => {
          setValue("cover_from_file", undefined);
          setValue("cover", content.cover);
        }}
      />
      <div className="mx-auto px-3" style={{ maxWidth: 700 }}>
        <input
          className="w-full bg-transparent text-center text-2xl font-bold leading-loose"
          {...register("title", { required: true })}
        />
        <TextAreaAutoSize
          className="mt-2 w-full bg-transparent text-center leading-loose text-custom-grey"
          {...register("description", { required: true })}
        />

        <div className="mt-4 flex flex-wrap items-center justify-center">
          <Controller
            name="theme_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) =>
              getThemeDropDown({
                field,
                themes,
                setValue,
                themesAsObject,
                selectedTheme: themesAsObject?.[
                  typeof field.value === "number" ? field.value : -1
                ] ?? {
                  dimensions: { slug: "physical" },
                  sub_dimensions: { name: "??" },
                  name: "??",
                },
              })
            }
          />
          <Controller
            name="content_type"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomSelect
                value={field.value}
                onValueChange={field.onChange}
                currentValue={
                  <IconByArticleType type={field.value} size={14} />
                }
              >
                {articleTypeOptions.map((type, i) => (
                  <Select.Item key={`articleTypeOptions${i}`} value={type}>
                    <Select.Icon>
                      <IconByArticleType type={type} size={14} />
                    </Select.Icon>
                    <Select.ItemText></Select.ItemText>
                  </Select.Item>
                ))}
              </CustomSelect>
            )}
          />

          <p className="ml-1 text-custom-grey">
            <input
              className="ml-1 w-20 bg-transparent text-right text-custom-grey"
              type="number"
              {...register("time_to_read_in_seconds", { valueAsNumber: true })}
            />{" "}
            secondes
          </p>

          <RoundedButton
            label="calculer"
            width="w-20"
            buttonColor="bg-custom-neutral400"
            className="ml-2 h-8"
            onPress={countWords}
          />
        </div>
      </div>

      <DisplayPlayer
        content_type_field_name="content_type"
        url_field_name="mux_playback_url"
        control={control}
        onChangeText="Changer"
        registerResult={register("mux_playback_url_from_file")}
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) {
            setValue("mux_playback_url_from_file", file);
            setValue("mux_playback_url", URL.createObjectURL(file));
          }
        }}
        onCancel={() => {
          setValue("mux_playback_url_from_file", undefined);
          setValue("mux_playback_url", content.cover);
        }}
        setDuration={setDuration}
      />
    </>
  );
}

type GetThemeDropDownProps = {
  field: ControllerRenderProps<
    Awaited<ReturnType<typeof getAdminContentById>>,
    "theme_id"
  >;
  themes?: Awaited<ReturnType<typeof getAdminThemes>>;
  themesAsObject: {
    [id: string]: Awaited<ReturnType<typeof getAdminThemes>>[0];
  };
  selectedTheme: Awaited<ReturnType<typeof getAdminThemes>>[0];
  setValue: UseFormSetValue<AdminContentType>;
};

function getThemeDropDown({
  field,
  themes,
  setValue,
  themesAsObject,
  selectedTheme,
}: GetThemeDropDownProps) {
  return (
    <CustomSelect
      value={field.value?.toString()}
      onValueChange={(value) => {
        field.onChange(parseInt(value));
        setValue(
          "dimensions.slug",
          themesAsObject?.[value]?.dimensions?.slug ?? "physical"
        );
      }}
      currentValue={
        <div
          className={`items-center bg-custom-${selectedTheme.dimensions.slug} text-custom-beige  mr-3 rounded-lg px-2 py-1 text-xs`}
        >
          {selectedTheme.name} ({selectedTheme.sub_dimensions.name})
        </div>
      }
    >
      {(themes ?? []).map((theme, i) => (
        <Select.Item
          key={`articleTypeOptions${i}`}
          value={theme.id.toString()}
          className={`flex flex-wrap items-center bg-custom-${
            theme.dimensions?.slug ?? "physical"
          } mr-3 rounded-lg px-2 py-1`}
        >
          <Select.ItemText className="text-custom-beige cursor-pointer text-xs">
            {theme.name} ({theme.sub_dimensions?.name?.toLowerCase() ?? ""})
          </Select.ItemText>
        </Select.Item>
      ))}
    </CustomSelect>
  );
}

function ContentPartsForm({
  control,
  getValues,
  content,
  contentPartToDelete,
  setContentPartToDelete,
  register,
  setValue,
}: ReturnType<typeof useForm<AdminContentType>> & {
  content: AdminContentType;
  contentPartToDelete: Set<number>;
  setContentPartToDelete: (value: Set<number>) => void;
}) {
  const { fields, remove, move, append, update } = useFieldArray({
    name: "content_parts",
    control,
    keyName: "field_array_id",
  });

  const marginTops: { [key: string]: string } = {
    title: "mt-4",
    quote: "mt-10",
    picture: "mt-10",
  };

  return (
    <div className={"mx-auto mt-16"}>
      {fields.map((content_part, index) => (
        <div
          key={`content_parts_${index}`}
          className={marginTops[content_part.content_part_type ?? ""] ?? ""}
        >
          <div className="flex w-full flex-row justify-between">
            <CustomSelect
              className="w-12"
              value={(index + 1).toString()}
              onValueChange={(value) => {
                move(index, parseInt(value));
              }}
            >
              {fields.map((_, i) => (
                <Select.Item
                  key={`content_part_order${i}`}
                  value={i.toString()}
                  className="pr-2 text-right"
                >
                  <Select.ItemText>{i + 1}</Select.ItemText>
                </Select.Item>
              ))}
            </CustomSelect>
            <Controller
              name={`content_parts.${index}.content_part_type`}
              control={control}
              render={({ field }) => (
                <CustomSelect
                  className="w-32"
                  value={content_part.content_part_type}
                  onValueChange={(value) => {
                    field.onChange(value);
                    update(index, {
                      ...(getValues().content_parts?.[index] ?? {}),
                      content_part_type: value,
                    });
                  }}
                  currentValue={content_part.content_part_type}
                >
                  {[
                    { value: "paragraph", name: "paragraphe" },
                    { value: "title", name: "titre" },
                    { value: "quote", name: "citation" },
                    { value: "picture", name: "image" },
                  ].map(({ value, name }, i) => (
                    <Select.Item
                      key={`contentPartPossibleTypes${i}`}
                      value={value}
                    >
                      <Select.ItemText>{name}</Select.ItemText>
                    </Select.Item>
                  ))}
                </CustomSelect>
              )}
            />
            <RoundedButton
              label="X"
              buttonColor="bg-custom-danger"
              width="w-8"
              className="h-8"
              onPress={() => {
                remove(index);
                if (content_part.id) {
                  contentPartToDelete.add(content_part.id);
                  setContentPartToDelete(contentPartToDelete);
                }
              }}
            />
          </div>
          <ContentPart
            content_part_type={content_part.content_part_type}
            dimension_slug={getValues("dimensions.slug")}
            registerTextResult={register(`content_parts.${index}.text_content`)}
            name={`content_parts.${index}.picture_url`}
            control={control}
            alt={content_part.text_content ?? ""}
            onChangeText="Changer l'image"
            registerImageResult={register(`content_parts.${index}.picture_url`)}
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) {
                setValue(`content_parts.${index}.picture_url_from_file`, file);
                setValue(
                  `content_parts.${index}.picture_url`,
                  URL.createObjectURL(file)
                );
              }
            }}
            onCancel={() => {
              setValue(
                `content_parts.${index}.picture_url_from_file`,
                undefined
              );
              setValue(
                `content_parts.${index}.picture_url`,
                content.content_parts?.[index]?.picture_url
              );
            }}
          />
        </div>
      ))}
      <RoundedButton
        label="Ajouter une partie"
        onPress={() => {
          append({
            content_part_type: "paragraph",
            position: fields.length + 1,
          });
        }}
      />
    </div>
  );
}

function CustomImagePicker<T extends FieldValues>({
  name,
  control,
  alt,
  imgClassName,
  registerResult,
  onChange,
  onChangeText,
  onCancel,
}: {
  name: Path<T>;
  control: ReturnType<typeof useForm<T>>["control"];
  alt: string;
  imgClassName?: string;
  registerResult: ReturnType<ReturnType<typeof useForm<T>>["register"]>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeText: string;
  onCancel: () => void;
}) {
  return (
    <div className="mt-10 mb-10">
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <img alt={alt} src={field.value} className={imgClassName} />
        )}
      />
      <div className="mb-12 flex w-full flex-row justify-between leading-loose">
        <label
          htmlFor={name + "_from_file_picker"}
          className="cursor-pointer text-center font-bold"
        >
          {onChangeText}
        </label>
        <input
          id={name + "_from_file_picker"}
          className="hidden"
          type="file"
          {...registerResult}
          onChange={onChange}
        />
        <p className="cursor-pointer text-center font-bold" onClick={onCancel}>
          {" "}
          annuler{" "}
        </p>
      </div>
    </div>
  );
}

// I have no idea why eslint doesn't recognise T as used in next line
// eslint-disable-next-line
type ContentPartProps<T extends FieldValues> = {
  content_part_type?: string;
  dimension_slug?: string;
  registerTextResult: ReturnType<ReturnType<typeof useForm<T>>["register"]>;
  registerImageResult: ReturnType<ReturnType<typeof useForm<T>>["register"]>;
} & Omit<Parameters<typeof CustomImagePicker<T>>[0], "registerResult">;

function ContentPart<T extends FieldValues>({
  content_part_type,
  dimension_slug,
  registerTextResult,
  registerImageResult,
  ...props
}: ContentPartProps<T>) {
  if (content_part_type === "paragraph") {
    return (
      <TextAreaAutoSize
        className="w-full bg-transparent leading-loose text-custom-grey"
        {...registerTextResult}
      />
    );
  }

  if (content_part_type === "title") {
    return (
      <h2 className="mb-4  text-xl font-semibold leading-loose">
        <TextAreaAutoSize
          className="w-full bg-transparent"
          {...registerTextResult}
        />
      </h2>
    );
  }

  if (content_part_type === "quote") {
    return (
      <div className="relative mb-10 flex flex-wrap items-center">
        <div
          className={`bg-custom-${
            dimension_slug ? dimension_slug : "physical"
          } absolute h-full w-2 py-4 opacity-50`}
        />
        <p
          className={`text-xl leading-loose text-custom-${
            dimension_slug ? dimension_slug : "physical"
          } physical ml-8 flex-grow`}
          style={{ flexBasis: 0 }}
        >
          〝
          <TextAreaAutoSize
            className="w-full bg-transparent"
            {...registerTextResult}
          />
          〞
        </p>
      </div>
    );
  }

  if (content_part_type === "picture") {
    return (
      <div className="mb-10">
        <CustomImagePicker registerResult={registerImageResult} {...props} />
      </div>
    );
  }

  return <></>;
}

function DisplayPlayer<T extends FieldValues>({
  url_field_name,
  control,
  registerResult,
  onChange,
  onChangeText,
  onCancel,
  content_type_field_name,
  setDuration,
}: {
  url_field_name: Path<T>;
  content_type_field_name: Path<T>;
  control: ReturnType<typeof useForm<T>>["control"];
  registerResult: ReturnType<ReturnType<typeof useForm<T>>["register"]>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeText: string;
  onCancel: () => void;
  setDuration: (duration: number) => void;
}) {
  return (
    <Controller
      name={content_type_field_name}
      control={control}
      render={({ field }) => {
        if (field.value === "article") return <></>;
        return (
          <div className={"mx-auto mt-6"}>
            <Controller
              name={url_field_name}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <ReactPlayer
                    url={field.value}
                    width="100%"
                    height="16"
                    controls
                    onDuration={setDuration}
                  />
                </>
              )}
            />
            <div className="mb-12 flex w-full flex-row justify-between leading-loose">
              <label
                htmlFor={url_field_name + "_from_file_picker"}
                className="cursor-pointer text-center font-bold"
              >
                {onChangeText}
              </label>
              <input
                id={url_field_name + "_from_file_picker"}
                className="hidden"
                type="file"
                {...registerResult}
                onChange={onChange}
              />
              <p
                className="cursor-pointer text-center font-bold"
                onClick={onCancel}
              >
                {" "}
                annuler{" "}
              </p>
            </div>
          </div>
        );
      }}
    />
  );
}
