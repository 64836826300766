import React, { useState } from "react";
import { ReactComponent as Logo } from "../../assets/svg/logo-o.svg";
import { ReactComponent as BurgerMenu } from "../../assets/svg/burger-menu.svg";
import colors from "../../theme/colors";
import Sidebar from "../sidebar/Sidebar";

const ResponsiveHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className="relative mt-5 flex w-full items-center pr-3">
        <Logo className="absolute right-1/2" fill={colors.beige} width={43} />
        <div className="ml-auto">
          <BurgerMenu
            onClick={() => onClickHandler()}
            className="cursor-pointer hover:opacity-60"
            fill={colors.beige}
            width={36}
          />
        </div>
      </header>
      <div
        className="fixed z-50 h-screen duration-300"
        style={{
          transform: isOpen ? "translateX(0%)" : "translateX(100%)",
          width: "185%",
        }}
      >
        <Sidebar onPressClose={() => setIsOpen(false)} />
      </div>
    </>
  );
};

export default ResponsiveHeader;
