import React, { useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import { Link, useSearchParams } from "react-router-dom";
import replays from "../datas/replays";
import CardArticleLocalVideo from "../components/card-article-local-video/CardArticleLocalVideo";
import ReactGA from "react-ga";
import ResponsiveHeader from "../components/responsive-header/ResponsiveHeader";

const ReplaysLibrary = () => {
  const [searchParams] = useSearchParams();
  const replaysToDisplay = searchParams.get("dimension")
    ? replays.filter((e) => e.dimension === searchParams.get("dimension"))
    : replays;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <div className="flex md:hidden">
        <ResponsiveHeader />
      </div>
      <div className="flex h-full flex-wrap">
        <div className="hidden md:flex">
          <Sidebar />
        </div>
        <div className="flex-grow p-8 md:pl-2" style={{ flexBasis: 0 }}>
          <div>
            <div className="flex items-center">
              <h1 className=" flex-grow text-2xl">
                Les replays des lives Moha
              </h1>
            </div>
            <h1 className="text-md mb-8 flex-grow text-custom-grey">
              Si tu as raté les lives ou que tu souhaites les revoir, retrouve
              les replays sur cette page
            </h1>
            {replays.length === 0 && (
              <p className="text-custom-grey">
                Pas encore de lives à afficher. Ça arrive bientôt ! 😉
              </p>
            )}
            <div className="2xl-grid:cols-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
              {replaysToDisplay.map((e, index) => {
                return (
                  <Link key={index} to={`/replay/${e.id}`}>
                    <CardArticleLocalVideo content={e} />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReplaysLibrary;
