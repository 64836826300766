import React from "react";
import { ReactComponent as Loading } from "../../assets/icons/loading.svg";
import colors from "../../theme/colors";
import { ReactComponent as FiestaPhysical } from "../../assets/svg/fiesta-physical.svg";
import { ReactComponent as MowiHappy } from "../../assets/svg/mowi-happy.svg";
import RoundedButton from "../rounded-button/RoundedButton";
import { useCurrentUser } from "../../services/me";

function DailyFormResults({ onClose }: { onClose: () => void }) {
  const { isLoading: isUserLoading } = useCurrentUser();

  if (isUserLoading)
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <Loading
          width={40}
          height={40}
          className="mb-6 animate-spin"
          fill={colors.yellow}
        />
        <p>Quelques petites secondes... nous calculons ton score ☀️</p>
      </div>
    );

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center overflow-hidden">
      <FiestaPhysical className="absolute z-0" height={"130%"} width={"130%"} />
      <div className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform">
        <div className="flex flex-col items-center justify-center">
          <MowiHappy width={100} height={75} className="mb-4" />
          <p>Ton score a été mis à jour ! ☀️</p>
          <div className="mt-2" style={{ width: 280 }}>
            <RoundedButton
              label="Découvrir mon nouveau score"
              onPress={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailyFormResults;
