import request from "../utils/AxiosWrap";

const fetchLibrary = () => {
  const token = localStorage.getItem("moha-at");

  return request("/api/v1/contents", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const fetchContentById = (contentId: number | string) => {
  const token = localStorage.getItem("moha-at");

  return request(`/api/v1/contents/${contentId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export function listContents(dimension: string | null | undefined) {
  const token = localStorage.getItem("moha-at");

  const dimensionValues = ["all", "physical", "social", "mental"];

  const trueDimension = dimensionValues.some((dv) => dv === dimension)
    ? dimension
    : "all";

  return request<
    {
      id: number;
      cover?: string;
      title?: string;
      content_type?: string;
      time_to_read_in_seconds?: number;
      dimensions: { slug?: string };
      sub_dimensions: { name?: string };
    }[]
  >(`/api/v1/contents/list/${trueDimension}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

type ContentType = {
  id: number;
  cover?: string;
  title?: string;
  description?: string;
  dimensions: { slug?: string };
  sub_dimensions: { name?: string };
  content_type?: string;
  time_to_read_in_seconds?: number;
  mux_playback_url?: string;
  content_parts: {
    content_part_type: string;
    text_content: string;
    picture_url: string;
    position: number;
  }[];
};

export function getContentByIdLight(id?: number | string) {
  if (!id) {
    const emptyContent: ContentType = {
      id: -1,
      dimensions: {},
      sub_dimensions: {},
      content_parts: [],
    };

    return Promise.resolve(emptyContent);
  }

  const token = localStorage.getItem("moha-at");

  return request<ContentType>(`/api/v1/contents/${id}/light`, {
    url: `/api/v1/contents/${id}/light`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function logVideo(contentId?: string) {
  const token = localStorage.getItem("moha-at");

  return request(`/api/v1/contents/${contentId ?? -1}/log-video`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

//todo refactor this ocne all routes are in tsx
export const libraryServices = {
  fetchLibrary,
  fetchContentById,
  logVideo,
};
