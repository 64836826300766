import React, { useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import CardContent from "../components/card-article/CardContent";
import { Link, useParams } from "react-router-dom";
import SeeMoreArrow from "../components/see-more-arrow/SeeMoreArrow";
import CardSkeletons from "../components/card-skeletons/CardSkeletons";
import replays from "../datas/replays";
import CardArticleLocalVideo from "../components/card-article-local-video/CardArticleLocalVideo";
import DimensionCardAccountInfos from "../components/dimension-card-account-infos/DimensionCardAccountInfos";
import ReactGA from "react-ga";
import ResponsiveHeader from "../components/responsive-header/ResponsiveHeader";
import { useQuery } from "@tanstack/react-query";
import { useCurrentUser } from "../services/me";
import { listContents } from "../services/library";

const isValidDimension = (dimension?: string) => {
  if (
    !dimension ||
    (dimension !== "physical" &&
      dimension !== "social" &&
      dimension !== "mental")
  ) {
    return null;
  }
  return dimension;
};

const HomePage = () => {
  const { dimension } = useParams();

  const { data: contents, isLoading } = useQuery({
    queryKey: ["listContentsQuery", dimension],
    queryFn: () =>
      isValidDimension(dimension) ? listContents(dimension) : undefined,
    refetchInterval: false,
  });

  const { data: user } = useCurrentUser();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <div className="flex md:hidden">
        <ResponsiveHeader />
      </div>
      <div className="flex h-full flex-wrap">
        <div className="z-10">
          <div className="hidden md:flex">
            <Sidebar />
          </div>
        </div>
        <div className="flex-grow p-8 pl-2" style={{ flexBasis: 0 }}>
          <div>
            {user && Object.keys(user).length > 0 && (
              <DimensionCardAccountInfos
                dimension={isValidDimension(dimension) ?? "physical"}
              />
            )}
            <div className="mb-8 flex items-center ">
              <h1 className=" flex-grow text-2xl">Ma bibliothèque bien-être</h1>
              <Link to={`/library?dimension=${dimension}`}>
                <SeeMoreArrow />
              </Link>
            </div>
            <div className="2xl-grid:cols-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
              {(contents?.length ?? 0) === 0 ||
                (isLoading && <CardSkeletons count={5} />)}
              {contents
                ?.sort(() => (Math.random() > 0.5 ? 1 : -1))
                ?.slice(0, 5)
                ?.map((content, i) => {
                  return (
                    <Link
                      key={`dimension_contents_${i}`}
                      to={`/content/${content.id}`}
                    >
                      <CardContent content={content} />
                    </Link>
                  );
                })}
            </div>
          </div>

          <div className="mt-12">
            <div className="mb-8 flex items-center ">
              <h1 className=" flex-grow text-2xl">Mes replays</h1>
              <Link to={`/replays?dimension=${dimension}`}>
                <SeeMoreArrow />
              </Link>
            </div>
            {replays.length === 0 && !isLoading && (
              <p className="text-custom-grey">
                Pas encore de lives à afficher. Ça arrive bientôt ! 😉
              </p>
            )}
            <div className="2xl-grid:cols-6 mb-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
              {isLoading && <CardSkeletons count={5} />}
              {!isLoading &&
                replays
                  .filter((e) => e.dimension === dimension)
                  .map((e, index) => {
                    return (
                      <Link key={index} to={`/replay/${e.id}`}>
                        <CardArticleLocalVideo content={e} />
                      </Link>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
