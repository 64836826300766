import React, { useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import { IconByArticleType } from "../utils/articles";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg";
import { libraryServices } from "../services/library";
import replays from "../datas/replays";
import ReactGA from "react-ga";
import ResponsiveHeader from "../components/responsive-header/ResponsiveHeader";

const ContentLocalVideo = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const content = replays.find((e) => {
    return e.id === (id ? parseInt(id, 10) : undefined);
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    libraryServices.logVideo(id);
  }, [id]);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex md:hidden">
        <ResponsiveHeader />
      </div>
      <div className="flex h-full flex-wrap">
        <div className="hidden md:flex">
          <Sidebar />
        </div>
        {content && (
          <div
            className="mx-auto flex-grow p-8 md:pl-2"
            style={{ flexBasis: 0, maxWidth: 700 }}
          >
            <div className="mb-8 inline-block">
              <div
                onClick={goBack}
                style={{ transform: "rotate(180deg)" }}
                className="flex cursor-pointer items-center justify-center rounded-full bg-custom-yellow p-2 transition-all hover:opacity-80"
              >
                <ArrowRight fill={"white"} height={25} width={25} />
              </div>
            </div>
            <div className="mx-auto px-3" style={{ maxWidth: 700 }}>
              <h1 className=" text-center text-2xl font-bold leading-loose">
                {content.title}
              </h1>
              <p className="mb-6 text-center text-custom-grey">
                avec {content.author}
              </p>
              <div className="mt-4 flex flex-wrap items-center justify-center">
                {content.subdimension && content.dimension && (
                  <div
                    className={`flex flex-wrap items-center bg-custom-${content.dimension} mr-3 rounded-lg px-2 py-1`}
                  >
                    <p className="text-custom-beige text-xs">
                      {content.subdimension.toLowerCase()}
                    </p>
                  </div>
                )}
                <IconByArticleType type={"video"} size={14} />
                <p className="ml-1 text-custom-grey">
                  {Math.ceil(content.duration / 60)} min
                </p>
              </div>
            </div>
            <div className={`mx-auto mt-6`}>
              <div>
                <iframe
                  className="mx-auto"
                  width="560"
                  height="315"
                  src={content.video}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
            {content.description && (
              <p className=" mt-8 leading-loose text-custom-grey">
                {content.description.split("\n").map((e) => {
                  return e.length > 0 ? <p>{e}</p> : <br />;
                })}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ContentLocalVideo;
