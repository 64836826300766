import React, { ReactNode, useEffect, useState } from "react";
import { ReactComponent as LogoO } from "../../assets/svg/logo-o.svg";
import { ReactComponent as Disconnect } from "../../assets/icons/disconnect.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import { ReactComponent as Power } from "../../assets/icons/power.svg";
import { ReactComponent as Replays } from "../../assets/icons/replays.svg";
import { ReactComponent as Library } from "../../assets/icons/library.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as CrossLarge } from "../../assets/icons/cross-large.svg";
import { ReactComponent as Info } from "../../assets/icons/info.svg";
import { ReactComponent as Score } from "../../assets/icons/all.svg";
import { ReactComponent as Physical } from "../../assets/icons/physical.svg";
import { ReactComponent as Social } from "../../assets/icons/social.svg";
import { ReactComponent as Mental } from "../../assets/icons/mental.svg";
import { ReactComponent as AngleRight } from "../../assets/icons/angle-right.svg";
import { ReactComponent as AngleLeft } from "../../assets/icons/angle-left.svg";
import DeleteAccountModal from "../delete-account-modal/DeleteAccountModal";
import colors from "../../theme/colors";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { loginServices } from "../../services/login";
import Joyride from "react-joyride";
import ReactGA from "react-ga";
import { useCurrentUser } from "../../services/me";

type SidebarElementProps = {
  id?: string;
  className?: string;
  isSelected?: boolean;
  onPress?: () => void;
  children?: ReactNode;
};
const SidebarElement = ({
  className = "",
  children,
  onPress,
  isSelected,
  id,
}: SidebarElementProps) => {
  return (
    <div>
      {isSelected && (
        <div className="absolute right-0 h-16 w-1.5 rounded-bl rounded-tl bg-custom-navy md:left-0 md:rounded-bl-none  md:rounded-br md:rounded-tl-none md:rounded-tr" />
      )}
      <li
        onClick={onPress ? () => onPress() : undefined}
        className={`flex cursor-pointer items-center justify-center ${className}`}
      >
        <div
          id={id}
          className={`${id} first-step text-base font-normal transition-all hover:opacity-60`}
        >
          {children}
        </div>
      </li>
    </div>
  );
};

//TODO: ne pas afficher le lien vers admin library a tous les utilisateurs
const Sidebar = ({
  onPressClose = () => undefined,
}: {
  onPressClose?: () => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isResponsive = window.innerWidth <= 768;
  const [runJoyride, setRunJoyride] = useState(false);
  const [openDimensions, setOpenDimensions] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: currentUser } = useCurrentUser();
  const steps = [
    {
      target: ".library",
      disableBeacon: true,
      content:
        "Bienvenue sur la plateforme de Moha ! Retrouve dans ta bibliothèque l'ensemble de tes articles, podcasts et vidéos.",
    },
    {
      target: ".replays",
      disableBeacon: true,
      content:
        "Vos ateliers bien-être et santé accessibles partout et tout le temps",
    },
  ];

  useEffect(() => {
    const onboardingDone = localStorage.getItem("moha-obd");

    if (!onboardingDone) {
      setTimeout(() => setRunJoyride(true), 1000);
    }
  }, []);

  const disconnect = async () => {
    try {
      ReactGA.event({
        category: "User",
        action: "Disconnected",
      });
      const authToken = localStorage.getItem("moha-at");
      await loginServices.disconnect({ token: authToken });
      localStorage.removeItem("moha-at");
      localStorage.removeItem("moha-rt");
      navigate("/");
    } catch (err) {}
  };

  const goToHome = () => {
    navigate("/home");
  };

  return (
    <aside className="h-screen" aria-label="Sidebar">
      {runJoyride && (
        <Joyride
          steps={steps}
          styles={{
            tooltipContent: { paddingBottom: 0 },
            tooltip: { fontFamily: "CircularStdBook, sans-serif" },
            options: {
              arrowColor: colors.beige,
              backgroundColor: colors.beige,
              overlayColor: "rgba(0, 0, 0, 0.5)",
              primaryColor: colors.navy,
              spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
              textColor: colors.navy,
              zIndex: 100,
            },
          }}
          callback={(e) => {
            e.action === "reset" && localStorage.setItem("moha-obd", "done");
          }}
          continuous
          locale={{
            back: "Retour",
            close: "Fermer",
            last: "Fermer",
            next: "Suivant",
            open: "Ouvrir",
            skip: "Passer",
          }}
        />
      )}
      <div className="relative flex h-full w-full md:w-32">
        <div className="width-inherit flex h-full items-center justify-center">
          <div className="safari-responsive fixed flex flex-col bg-custom-yellow pb-24 md:mb-8 md:mt-8 md:rounded-3xl md:pb-24 md:pt-8">
            {!isResponsive ? (
              <div
                onClick={goToHome}
                className="mt-4 flex-shrink cursor-pointer transition-all hover:opacity-80"
              >
                <LogoO fill={colors.beige} width={32} className="mx-auto" />
              </div>
            ) : (
              <div
                onClick={() => onPressClose()}
                className="mt-4 flex-shrink cursor-pointer transition-all hover:opacity-80"
              >
                <CrossLarge fill={colors.navy} width={35} className="mx-auto" />
              </div>
            )}
            <ul className="flex flex-grow flex-col justify-center">
              <Link to="/">
                <SidebarElement
                  id="home"
                  isSelected={location.pathname.includes("home")}
                  className="px-8 py-4"
                >
                  <Home fill={colors.navy} width={28} height={28} />
                </SidebarElement>
              </Link>
              <div
                className="relative flex flex-col items-center justify-center px-8 py-4"
                onMouseOver={() => setOpenDimensions(true)}
                onMouseLeave={() => setOpenDimensions(false)}
              >
                <Score width={28} height={28} />
                {!isResponsive ? (
                  <div className="absolute right-3 mt-0.5 self-center">
                    <AngleRight width={15} height={15} />
                  </div>
                ) : (
                  <div className="absolute left-3 mt-0.5 self-center">
                    <AngleLeft width={15} height={15} />
                  </div>
                )}
                {openDimensions && (
                  <div
                    className={` ${
                      isResponsive ? "right-full" : "left-full"
                    } absolute top-5 z-10 rounded-bl-xl rounded-tl-xl bg-custom-lightYellow px-8 pb-2 pt-4 md:rounded-bl-none md:rounded-br-xl md:rounded-tl-none md:rounded-tr-xl`}
                  >
                    <Link to={`/dimension/physical`}>
                      <div className="my-2 inline-block cursor-pointer rounded-full bg-custom-physical px-2 py-2 transition-all hover:opacity-80">
                        <Physical fill={colors.beige} width={18} height={18} />
                      </div>
                    </Link>
                    <Link to={`/dimension/mental`}>
                      <div className="my-2 inline-block cursor-pointer rounded-full bg-custom-mental px-2 py-2 transition-all hover:opacity-80">
                        <Mental fill={colors.beige} width={18} height={18} />
                      </div>
                    </Link>
                    <Link to={`/dimension/social`}>
                      <div className="my-2 inline-block cursor-pointer rounded-full bg-custom-social px-2 py-2 transition-all hover:opacity-80">
                        <Social fill={colors.beige} width={18} height={18} />
                      </div>
                    </Link>
                  </div>
                )}
              </div>
              <Link to="/library">
                <SidebarElement
                  id="library"
                  isSelected={
                    location.pathname.includes("library") &&
                    !location.pathname.includes("admin")
                  }
                  className="px-8 py-4"
                >
                  <Library fill={colors.navy} width={28} height={28} />
                </SidebarElement>
              </Link>
              <Link to="/replays">
                <SidebarElement
                  id="replays"
                  className="py-4"
                  isSelected={location.pathname.includes("replays")}
                >
                  <Replays fill={colors.navy} width={28} height={28} />
                </SidebarElement>
              </Link>
              {currentUser?.is_admin ? (
                <Link to="/admin/library">
                  <SidebarElement
                    id="admin-library"
                    className="py-4"
                    isSelected={location.pathname.includes("admin/library")}
                  >
                    <LogoO fill={colors.navy} width={28} height={28} />
                  </SidebarElement>
                </Link>
              ) : null}
            </ul>
            <div>
              <div>
                <SidebarElement
                  onPress={() =>
                    window.open(
                      "https://mohatoha.typeform.com/to/kmaWNwmm",
                      "_blank"
                    )
                  }
                  className="px-8 py-4"
                >
                  <Info fill={colors.navy} width={28} height={28} />
                </SidebarElement>
              </div>
              <div
                className="relative flex flex-col items-center justify-center px-8 py-4"
                onMouseOver={() => setOpenSettings(true)}
                onMouseLeave={() => setOpenSettings(false)}
              >
                <Power width={28} height={28} />
                {!isResponsive ? (
                  <div className="absolute right-3 mt-0.5 self-center">
                    <AngleRight width={15} height={15} />
                  </div>
                ) : (
                  <div className="absolute left-3 mt-0.5 self-center">
                    <AngleLeft width={15} height={15} />
                  </div>
                )}
                {openSettings && (
                  <div
                    className={` ${
                      isResponsive ? "right-full" : "left-full"
                    } absolute top-5 z-10 w-64 rounded-bl-xl rounded-tl-xl bg-custom-yellow p-1 px-2 pb-2  md:rounded-bl-none md:rounded-br-xl md:rounded-tl-none md:rounded-tr-xl`}
                  >
                    <div
                      onClick={disconnect}
                      className="flex cursor-pointer justify-between py-3 pl-1"
                    >
                      <p>Déconnexion</p>

                      <Disconnect fill={colors.navy} width={26} height={26} />
                    </div>
                    <div
                      onClick={() => setIsModalOpen(true)}
                      className="flex cursor-pointer justify-between rounded-xl bg-custom-lighterYellow px-1 py-3"
                    >
                      <p className="text-custom-danger">Supprimer mon compte</p>

                      <Delete width={26} height={26} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <DeleteAccountModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </aside>
  );
};

export default Sidebar;
