type ReactButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type MohaButtonProps = {
  mohaType?: "primary" | "secondary" | "tertiary";
  color?: "blue" | "yellow";
  circle?: boolean;
} & ReactButtonProps;

const ColoredButton = {
  primary: {
    blue: ({ className, children, ...props }: ReactButtonProps) => (
      <button
        disabled
        className={`${className} h-button w-button border-4 border-custom-blueNavy300 border-opacity-30 active:border-custom-blueNavy400 active:border-opacity-30`}
        {...props}
      >
        <div className="m-[4px] flex h-full w-full items-center justify-center rounded-full bg-custom-blueNavy300  text-white hover:bg-opacity-80 active:bg-custom-blueNavy400 active:bg-opacity-100">
          {children}
        </div>
      </button>
    ),

    yellow: ({ className, children, ...props }: ReactButtonProps) => (
      <button
        disabled
        className={`${className} h-button w-button border-4 border-custom-yellow600 border-opacity-30 active:border-custom-yellow700 active:border-opacity-30`}
        {...props}
      >
        <div className="m-[4px] flex h-full w-full items-center justify-center rounded-full bg-custom-yellow600  text-white hover:bg-opacity-70 active:bg-custom-yellow700 active:bg-opacity-100">
          {children}
        </div>
      </button>
    ),
  },

  secondary: {
    blue: ({ className, children, ...props }: ReactButtonProps) => (
      <button
        disabled
        className={`${className} h-button w-button border-4 border-custom-blueNavy300 hover:bg-custom-blueNavy300 active:border-custom-blueNavy400 active:bg-transparent`}
        {...props}
      >
        <div className="m-[4px] flex h-full w-full items-center justify-center rounded-full text-custom-blueNavy300 hover:bg-custom-blueNavy300 hover:text-white active:bg-custom-blueNavy100 active:bg-opacity-30 active:text-custom-blueNavy300">
          {children}
        </div>
      </button>
    ),

    yellow: ({ className, children, ...props }: ReactButtonProps) => (
      <button
        disabled
        className={`${className} h-button w-button border-4 border-custom-yellow600 hover:bg-custom-yellow600 active:border-custom-yellow700 active:bg-transparent`}
        {...props}
      >
        <div className="m-[4px] flex h-full w-full items-center justify-center rounded-full text-custom-yellow600 hover:bg-custom-yellow600 hover:text-white active:bg-custom-yellow700 active:bg-opacity-30 active:text-custom-yellow700">
          {children}
        </div>
      </button>
    ),
  },

  disabled: {
    blue: ({ disabled: _, children, ...props }: ReactButtonProps) => (
      <button disabled {...props}>
        <div className="m-[4px] flex h-full w-full items-center justify-center rounded-full bg-custom-neutral200 bg-opacity-50 text-custom-neutral500">
          {children}
        </div>
      </button>
    ),

    yellow: ({ disabled: _, children, ...props }: ReactButtonProps) => (
      <button disabled {...props}>
        <div className="m-[4px] flex h-full w-full items-center justify-center rounded-full bg-custom-beige100 bg-opacity-50 text-custom-yellow700">
          {children}
        </div>
      </button>
    ),
  },
} as const;

function CustomButton({
  mohaType = "primary",
  color = "blue",
  disabled,
  circle,
  className,
  ...props
}: MohaButtonProps) {
  if (mohaType === "tertiary") {
    return (
      <button
        className={`${className} flex h-button w-button cursor-not-allowed flex-row items-center`}
        {...props}
      />
    );
  }

  const dimension = circle ? "h-button w-buttonRounded" : "h-button w-button";

  const clickable = disabled ? "cursor-not-allowed" : "cursor-pointer";

  const SelectedColoredButton =
    ColoredButton[disabled ? "disabled" : mohaType][color];

  return (
    <SelectedColoredButton
      disabled={disabled}
      className={`${className} ${dimension} justify-center rounded-full ${clickable}`}
      {...props}
    />
  );
}

export function PrimaryButton({ mohaType: _, ...props }: MohaButtonProps) {
  return <CustomButton {...props} />;
}

export function SecondaryButton({ mohaType: _, ...props }: MohaButtonProps) {
  return <CustomButton mohaType="secondary" {...props} />;
}

export function TertiaryButton({ mohaType: _, ...props }: MohaButtonProps) {
  return <CustomButton mohaType="tertiary" {...props} />;
}

export function CircleButton({ circle: _, ...props }: MohaButtonProps) {
  return <CustomButton circle {...props} />;
}
