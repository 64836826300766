import { ReactComponent as Podcast } from "../assets/icons/podcast.svg";
import { ReactComponent as Video } from "../assets/icons/slideshow.svg";
import { ReactComponent as Article } from "../assets/icons/document-text.svg";

type ArticleTypeIconProps = {
  type?: string;
  size?: number;
  color?: string;
  width?: string | number;
  height?: string | number;
  viewBox?: string;
  preserveAspectRatio?: string;
  title?: string;
  fill?: string;
  className?: string;
};

const articleTypeIcons: {
  [key: string]: React.FC<ArticleTypeIconProps>;
} = {
  podcast: ({ size, color, ...props }) => (
    <Podcast
      fill={color || "#64748b"}
      width={size ?? 20}
      height={size ?? 20}
      {...props}
    />
  ),
  video: ({ size, color, ...props }) => (
    <Video
      fill={color || "#64748b"}
      width={size ?? 20}
      height={size ?? 20}
      {...props}
    />
  ),
  article: ({ size, color, ...props }) => (
    <Article
      fill={color || "#64748b"}
      width={size ?? 20}
      height={size ?? 20}
      {...props}
    />
  ),
};

export const articleTypeOptions = Object.keys(articleTypeIcons);

export function IconByArticleType({
  type,
  size,
  color,
  ...props
}: ArticleTypeIconProps) {
  const Icon = articleTypeIcons[type ?? "article"] ?? articleTypeIcons.article;

  return <Icon size={size} color={color} {...props} />;
}
