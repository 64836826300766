import React from "react";
import { IconByArticleType } from "../../utils/articles";
type CardContentProps = {
  content: {
    cover?: string;
    title?: string;
    content_type?: string;
    time_to_read_in_seconds?: number;
    dimensions: { slug?: string };
    sub_dimensions: { name?: string };
  };
  onPress?: () => void;
};
function CardContent({ content, onPress }: CardContentProps) {
  return (
    <div
      onClick={onPress}
      style={{ minHeight: 165 }}
      className="flex h-full w-full grow cursor-pointer flex-col overflow-hidden rounded-3xl bg-white shadow-lg transition-all"
    >
      <img
        className="h-28 w-full flex-shrink object-cover"
        alt={"Article"}
        src={content?.cover || ""}
      />
      <div className="flex h-full flex-grow flex-col p-3">
        <p className=" h-full line-clamp-2">{content?.title || ""}</p>
        <div className="mt-5 flex items-center justify-between">
          <div
            className={`flex flex-wrap items-center bg-custom-${content?.dimensions?.slug} rounded-lg px-2 py-1`}
          >
            <p className="text-xs text-custom-beige">
              {content?.sub_dimensions?.name?.toLowerCase() ?? ""}
            </p>
          </div>
          <div className="flex flex-wrap items-center">
            <IconByArticleType type={content?.content_type} size={14} />
            <p className=" ml-1 text-xs text-custom-grey">
              {typeof content?.time_to_read_in_seconds === "number"
                ? Math.ceil(content?.time_to_read_in_seconds / 60)
                : 0}{" "}
              min
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardContent;
