import React, { useEffect, useState } from "react";
import { ReactComponent as Mowi } from "../../assets/svg/mowi.svg";
import { ReactComponent as MentalPicto } from "../../assets/svg/mental-picto.svg";
import { ReactComponent as SocialPicto } from "../../assets/svg/social-picto.svg";
import { ReactComponent as PhysicalPicto } from "../../assets/svg/physical-picto.svg";
import MainScore from "../../assets/png/main-score.png";
import mentalShape from "../../assets/png/mental-shape.png";
import socialShape from "../../assets/png/social-shape.png";
import physicalShape from "../../assets/png/physical-shape.png";
import RoundedButton from "../rounded-button/RoundedButton";
import DimensionScore from "../dimension-score/DimensionScore";
import DailyFormModal from "../daily-form-modal/DailyFormModal";
import { formServices } from "../../services/form";
import moment from "moment";
import "moment/locale/fr";
import ReactGA from "react-ga";
import { useCurrentUser } from "../../services/me";

moment.locale("fr");

const CardAccountInfos = () => {
  const date = new Date();
  const { data: user } = useCurrentUser();
  const [isDailyOpen, setIsDailyOpen] = useState(false);
  const [isDailyAvailable, setIsDailyAvailable] = useState(false);
  const [isFetchingForm, setIsFetchingForm] = useState(false);

  useEffect(() => {
    const getForm = async () => {
      const response = await formServices.fetchNextFormToFill();
      if (response && response.form && response.form === "daily") {
        setIsDailyAvailable(true);
      }
    };
    getForm();
  }, []);

  return (
    <div className="mb-8 w-full rounded-3xl bg-custom-lighterYellow md:flex md:flex-wrap">
      <div className="w-full p-6 md:w-1/2">
        <div className="flex flex-wrap items-center">
          <h1 className=" text-3xl font-bold">
            Hello,{" "}
            {user && user.first_name && <span>{user.first_name} ! ☀️</span>}
          </h1>
          {(!user || !user.first_name) && (
            <>
              <div
                className="mx-3 h-4 w-40 animate-pulse
                rounded-full bg-custom-lightGrey bg-opacity-60 transition-all hover:opacity-75"
              />
              <span className=" text-3xl font-bold"> ! ☀️️</span>
            </>
          )}
        </div>
        <p className="mb-4 text-lg ">
          Nous sommes le {moment(date).format("dddd D MMMM")}
        </p>
        <p>
          {isDailyAvailable
            ? "Il est l’heure de faire ton évaluation pour réévaluer ton bien-être et personnaliser ton programme ! ⬇️"
            : "Bienvenue sur ton espace Moha. Consulte les contenus les plus adaptés à toi pour améliorer ton bien-être au quotidien !"}
        </p>
        {isDailyAvailable && (
          <div className="relative mt-8" style={{ maxWidth: 250 }}>
            <Mowi
              width={48}
              height={48}
              className="mowi-bounce absolute right-0 hidden md:block"
            />
            <RoundedButton
              isLoading={isFetchingForm}
              onPress={() => {
                ReactGA.event({
                  category: "User",
                  action: "Started daily evaluation",
                });
                setIsFetchingForm(true);
                setIsDailyOpen(true);
              }}
              label="Lancer mon évaluation"
            />
          </div>
        )}
      </div>
      <div style={{ minHeight: 250 }} className="relative flex-wrap md:w-1/2">
        <img
          alt="Social shape"
          className="absolute h-full w-full"
          src={socialShape}
        />
        <img
          alt="Physical shape"
          className="absolute h-full w-full"
          src={physicalShape}
        />
        <img
          alt="Mental shape"
          className="absolute h-full w-full"
          src={mentalShape}
        />
        <>
          <div
            className="absolute top-5 lg:w-44"
            style={{
              left: "5%",
            }}
          >
            <MentalPicto width={35} height={35} />
            <DimensionScore
              className="relative mt-4 lg:ml-24"
              score={user?.wellbeing_mental_score ?? 0}
            />
          </div>
          <div
            className="absolute bottom-5 flex justify-between lg:w-44"
            style={{
              left: "10%",
            }}
          >
            <DimensionScore
              className="mr-4"
              score={user?.wellbeing_social_score ?? 0}
            />
            <SocialPicto width={35} height={35} />
          </div>
          <div
            className="absolute top-12 flex h-24 flex-col items-center justify-between lg:w-32"
            style={{
              right: "10%",
            }}
          >
            <DimensionScore score={user?.wellbeing_physical_score ?? 0} />
            <PhysicalPicto width={35} height={35} />
          </div>
        </>

        <div
          className="w absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform"
          style={{ width: 150, height: 150 }}
        >
          <img alt="Main score" src={MainScore} className="h-full w-full" />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            <p className="text-center text-2xl font-bold">
              {Math.round(user?.wellbeing_score ?? 0)}
            </p>
            <div className="left-3 top-12 h-1 w-12 rounded-xl bg-custom-navy"></div>
            <p className="text-center text-xl text-custom-darkGrey">100</p>
          </div>
        </div>
      </div>
      {isDailyOpen && (
        <DailyFormModal
          setIsDailyAvailable={setIsDailyAvailable}
          setIsFetchingForm={setIsFetchingForm}
          isOpen={isDailyOpen}
          onClose={() => setIsDailyOpen(false)}
        />
      )}
    </div>
  );
};

export default CardAccountInfos;
