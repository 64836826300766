import { useQuery } from "@tanstack/react-query";
import request from "../utils/AxiosWrap";

function fetchMe() {
  const token = localStorage.getItem("moha-at");
  return request<{
    id: number;
    email: string;
    is_admin: boolean;
    first_name: string;
    wellbeing_score: number;
    physical_exp: number;
    mental_exp: number;
    social_exp: number;
    company_id: number;
    avatar: string;
    wellbeing_physical_score: number;
    wellbeing_mental_score: number;
    wellbeing_social_score: number;
    guided_tour_step: number;
    cgu: boolean;
    newsletter: boolean;
    notification: boolean;
    score: {
      moha_point: number;
      score_id: number;
    };
    free_time_out: boolean;
    physical_remark: string;
    mental_remark: string;
    social_remark: string;
  }>(`/api/v1/me`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function useCurrentUser() {
  const useMe = useQuery({
    queryKey: ["me"],
    queryFn: fetchMe,
    refetchInterval: false,
  });

  return useMe;
}
