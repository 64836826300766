import React, { useEffect } from "react";
import { TextInput } from "../components/input/Input";
import { resetPassword } from "../services/login";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as AngleRight } from "../assets/icons/angle-right.svg";
import LoginShape from "../assets/png/login-shape.png";
import ReactGA from "react-ga";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { LoaderInButton, PrimaryButton } from "../components/buttons";

function ForgotPwd() {
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (email: string) => {
      if (!email?.includes("@")) {
        throw new Error("email");
      }

      return resetPassword({
        user: { email },
      });
    },

    onSuccess: () => {
      toast.success(`Un e-mail t’a été envoyé pour changer ton mot de passe`);
      navigate("/");
    },

    onError: (error: Error) => {
      if (error.message === "email")
        return toast.error(`Renseigne ton adresse e-mail pour te connecter`);
      toast.error(
        `L’adresse e-mail n’est pas associée à un compte existant. Vérifie le mail que tu as indiqué️`
      );
    },
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="relative flex h-screen w-screen flex-wrap items-center justify-center">
      <div className="flex w-full items-center justify-center xl:w-1/2">
        <div style={{ maxWidth: 450 }} className="z-20 px-3">
          <div className="relative mt-6 mb-12">
            <div className="mb-16 inline-block">
              <div
                onClick={() => navigate("/")}
                style={{ transform: "rotate(180deg)" }}
                className="flex cursor-pointer items-center justify-center rounded-full transition-all hover:opacity-80"
              >
                <AngleRight height={25} width={25} />
              </div>
            </div>
            <h1 className="mb-2  text-center text-2xl">
              Mot de passe oublié ?
            </h1>
            <p className="text-center" style={{ maxWidth: 450 }}>
              Indique ton adresse e-mail ci-dessous. Tu recevras un mail de
              confirmation pour récuperer ton mot de passe
            </p>
          </div>
          <form
            onSubmit={handleSubmit(({ email }) => {
              mutate(email);
            })}
          >
            <Controller
              name="email"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextInput
                  className="mb-6"
                  placeholder="karine@moha.com"
                  label="Adresse e-mail"
                  type="email"
                  required
                  {...field}
                />
              )}
            />
            <div className="ml-auto mr-auto" style={{ width: 300 }}>
              <PrimaryButton
                className="mt-12"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? <LoaderInButton /> : "Récuperer mon mot de passe"}
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
      <div className="hidden w-1/2 xl:block">
        <img
          alt="Shape"
          className="absolute right-0 top-0 h-screen"
          src={LoginShape}
        />
      </div>
    </div>
  );
}

export default ForgotPwd;
