import request from "../utils/AxiosWrap";

export type AdminContentType = {
  id: number | "new";
  updated_at: Date;
  status?: number;
  cover?: string;
  cover_from_file?: File;
  title?: string;
  description?: string;
  dimensions: { slug?: string };
  sub_dimensions: { name?: string };
  theme_id?: number;
  content_type?: string;
  time_to_read_in_seconds?: number;
  mux_playback_url?: string;
  mux_playback_url_from_file?: File;
  last_publish_date?: Date;
  last_edited_by_user?: {
    first_name: string;
    email: string;
  };
  content_parts?: {
    id?: number;
    content_part_type?: string;
    text_content?: string;
    picture_url?: string;
    picture_url_from_file?: File;
    position?: number;
  }[];
};

const emptyContent: AdminContentType = {
  id: "new",
  updated_at: new Date(),
  status: 0,
  content_type: "article",
  dimensions: {},
  sub_dimensions: {},
  title: "Titre",
  description: "Description",
  time_to_read_in_seconds: 0,
};

export async function getAdminContentById(id?: number | string) {
  if (id === "new") return emptyContent;
  if (!id) id = -1;

  const token = localStorage.getItem("moha-at");

  return request<AdminContentType>(`/api/v1/admin/contents/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => {
    data.cover_from_file = undefined;
    data.mux_playback_url_from_file = undefined;

    return data;
  });
}

export type AdminTheme = {
  id: number;
  name: string;
  dimensions: { slug?: string };
  sub_dimensions: { name?: string };
};

export function getAdminThemes() {
  const token = localStorage.getItem("moha-at");

  return request<AdminTheme[]>(`/api/v1/admin/themes`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateAdminContent({
  newContent,
  content_parts_to_delete,
}: {
  newContent: AdminContentType;
  content_parts_to_delete: number[];
}) {
  const token = localStorage.getItem("moha-at");

  const {
    content_parts: doNotUse,
    cover_from_file,
    cover,
    dimensions,
    sub_dimensions,
    mux_playback_url,
    mux_playback_url_from_file,
    ...content
  } = newContent;

  const formatted_content_parts = newContent.content_parts?.map((cp, i) => ({
    ...cp,
    position: i,
  }));

  const files = new Map<string, File>();

  const content_parts_with_file =
    formatted_content_parts
      ?.filter(
        (content_part) =>
          content_part.picture_url_from_file?.name &&
          content_part.picture_url_from_file?.size > 0
      )
      .map(({ picture_url_from_file, position }) => {
        files.set(position.toString(), picture_url_from_file as File); // considering the filter it is safe to assume picture_url_from_file is not undefined
        return {
          position,
          picture_url_from_file: {
            name: picture_url_from_file?.name,
            content_type: picture_url_from_file?.type,
            byte_size: picture_url_from_file?.size,
          },
        };
      }) || [];

  const formattedCoverFromFiles =
    cover_from_file && cover_from_file.size > 0
      ? {
          name: cover_from_file?.name,
          content_type: cover_from_file?.type,
          byte_size: cover_from_file?.size,
        }
      : undefined;

  const formattedMuxFromFiles =
    mux_playback_url_from_file && mux_playback_url_from_file.size > 0
      ? {
          name: mux_playback_url_from_file?.name,
          content_type: mux_playback_url_from_file?.type,
          byte_size: mux_playback_url_from_file?.size,
        }
      : undefined;

  const {
    cover_url,
    cover_key,
    content_parts_urls,
    mux_playback_key,
    mux_playback_url: mux_playback_url_from_api,
  } = formattedCoverFromFiles ||
  content_parts_with_file.length > 0 ||
  formattedMuxFromFiles
    ? await request<{
        cover_url?: string;
        cover_key?: string;
        mux_playback_key?: string;
        mux_playback_url?: string;
        content_parts_urls?: {
          [position: string]: {
            key: string;
            url: string;
          };
        };
      }>(`/api/v1/admin/contents/files-url`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          cover_from_file: formattedCoverFromFiles,
          mux_playback_url_from_file: formattedMuxFromFiles,
          content_parts: content_parts_with_file,
        },
      })
    : {
        cover_url: undefined,
        cover_key: undefined,
        content_parts_urls: undefined,
        mux_playback_key: undefined,
        mux_playback_url: undefined,
      };

  if (cover_url) {
    await request(cover_url, {
      method: "PUT",
      data: newContent.cover_from_file,
    });
  }

  if (mux_playback_url_from_api) {
    await request(mux_playback_url_from_api, {
      method: "PUT",
      data: newContent.mux_playback_url_from_file,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).catch((error) => {
      throw error;
    });
  }

  content_parts_urls &&
    (await Promise.all(
      Object.entries(content_parts_urls).map(
        async ([position, { key, url }]) => {
          return await request(url, {
            method: "PUT",
            data: files.get(position),
          });
        }
      )
    ));

  const formatedNewContent = {
    ...content,
    status: content.status ?? 0,
    cover_from_file: newContent.cover_from_file?.name
      ? {
          filename: newContent.cover_from_file.name,
          key: cover_key,
          content_type: newContent.cover_from_file.type,
          byte_size: newContent.cover_from_file.size,
        }
      : undefined,
    mux_playback_key: mux_playback_key,
    content_parts_to_delete,
    content_parts: formatted_content_parts
      ? formatted_content_parts.map((content_part) => ({
          ...content_part,
          picture_url_from_file: content_part.picture_url_from_file
            ? {
                key: content_parts_urls?.[content_part.position]?.key,
                filename: content_part.picture_url_from_file.name,
                content_type: content_part.picture_url_from_file.type,
                byte_size: content_part.picture_url_from_file.size,
              }
            : undefined,
        }))
      : undefined,
  };

  const result = await request<{
    success: boolean;
    content_id: number;
  }>(`/api/v1/admin/contents/modify`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formatedNewContent,
  });

  return result;
}

export function listAdminContents(dimension: string | null | undefined) {
  const token = localStorage.getItem("moha-at");

  const dimensionValues = ["all", "physical", "social", "mental"];

  const trueDimension = dimensionValues.some((dv) => dv === dimension)
    ? dimension
    : "all";

  return request<
    {
      id: number;
      status?: number;
      cover?: string;
      title?: string;
      content_type?: string;
      time_to_read_in_seconds?: number;
      dimensions: { slug?: string };
      sub_dimensions: { name?: string };
      last_edited_by_user?: { first_name: string };
      updated_at: string;
    }[]
  >(`/api/v1/admin/contents/list/${trueDimension}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
