import { useNavigate } from "react-router-dom";
import { ReactComponent as AngleLeft } from "../../assets/icons/angle-left.svg";
import { TertiaryButton } from "./Button";

export function GoBackButton() {
  const navigate = useNavigate();

  return (
    <TertiaryButton
      onClick={() => {
        navigate(-1);
      }}
    >
      <AngleLeft className="mr-3" height={14} />
      <p className=" text-xl">Retour</p>
    </TertiaryButton>
  );
}
