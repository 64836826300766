import React, { useEffect } from "react";
import { PasswordInput } from "../components/input/Input";
import { passwordRegex, updatePassword } from "../services/login";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as AngleRight } from "../assets/icons/angle-right.svg";
import LoginShape from "../assets/png/login-shape.png";
import ReactGA from "react-ga";
import { Controller, useForm } from "react-hook-form";
import { LoaderInButton, PrimaryButton } from "../components/buttons";
import { useMutation } from "@tanstack/react-query";

function ResetPwd() {
  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get("reset_password_token");
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      password: "",
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (password: string) => {
      if (!passwordRegex.test(password)) {
      }

      return updatePassword({
        password,
        resetPasswordToken,
      });
    },

    onSuccess: () => {
      toast.success(`Ton mot de passe a bien été mis à jour !️`);
      navigate("/");
    },
    onError: (error: Error) => {
      if (error.message === "password")
        return toast.error(
          `Ton mot de passe doit contenir au moins 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial️`
        );
      toast.error(
        `Oups ! Il semblerait que la plateforme ne soit pas très réveillée aujourd’hui. Réessaye un peu plus tard`
      );
    },
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="relative flex h-screen w-screen flex-wrap items-center justify-center">
      <div className="flex w-full items-center justify-center xl:w-1/2">
        <div style={{ maxWidth: 450 }} className="z-20 px-3">
          <div className="relative mt-6 mb-12">
            <div className="mb-16 inline-block">
              <div
                onClick={() => navigate("/")}
                style={{ transform: "rotate(180deg)" }}
                className="flex cursor-pointer items-center justify-center rounded-full transition-all hover:opacity-80"
              >
                <AngleRight height={25} width={25} />
              </div>
            </div>
            <h1 className="mb-2  text-center text-2xl">
              Modifie ton mot de passe
            </h1>
            <p className="text-center" style={{ maxWidth: 450 }}>
              Ton mot de passe doit comporter au moins 8 caractères et inclure
              au minimum 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère
              spécial{" "}
            </p>
          </div>
          <form
            onSubmit={handleSubmit(({ password }) => {
              mutate(password);
            })}
          >
            <Controller
              name="password"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <PasswordInput
                  className="mb-6"
                  placeholder="********"
                  label="Nouveau mot de passe"
                  required
                  {...field}
                />
              )}
            />
            <div className="ml-auto mr-auto" style={{ width: 300 }}>
              <PrimaryButton
                className="mt-12"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? <LoaderInButton /> : "Changer mon mot de passe"}
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
      <div className="hidden w-1/2 xl:block">
        <img
          alt="Shape"
          className="absolute right-0 top-0 h-screen"
          src={LoginShape}
        />
      </div>
    </div>
  );
}

export default ResetPwd;
