import React, { ReactNode } from "react";
import { useNavigate } from "react-router";
import { DefaultLayout } from "./DefaultLayout";
import { ReactComponent as AngleLeft } from "../../assets/icons/angle-left.svg";

export function GoBackLayout({ children }: { children?: ReactNode }) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <DefaultLayout>
      <div
        className="mx-auto flex-grow p-8 md:pl-2"
        style={{ flexBasis: 0, maxWidth: 700 }}
      >
        <div className="mb-8 inline-block">
          <div
            onClick={goBack}
            className="flex cursor-pointer flex-wrap items-center justify-center p-2 transition-all hover:opacity-80"
          >
            <AngleLeft className="mr-3" height={14} />
            <p className=" text-xl">Retour</p>
          </div>
        </div>
        {children}
      </div>
    </DefaultLayout>
  );
}
