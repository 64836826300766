import React from "react";
import { ReactComponent as Physical } from "../assets/icons/physical.svg";
import { ReactComponent as Social } from "../assets/icons/social.svg";
import { ReactComponent as Mental } from "../assets/icons/mental.svg";

export const getColorByDimension = (dimension: string) => {
  return dimension;
};

export const getNameByDimension = (dimension: string, isFemale?: boolean) => {
  switch (dimension) {
    case "physical":
      return "Physique";
    case "mental":
      return isFemale ? "Mentale" : "Mental";
    case "social":
      return isFemale ? "Relationnelle" : "Relationnel";
    default:
      return "";
  }
};

export const getIconByDimension = (
  dimension: string,
  size: number,
  color: string
) => {
  switch (dimension) {
    case "physical":
      return (
        <Physical
          fill={color || "#f5536c"}
          width={size || 20}
          height={size || 20}
        />
      );
    case "social":
      return (
        <Social
          fill={color || "#69cdab"}
          width={size || 20}
          height={size || 20}
        />
      );
    default:
      return (
        <Mental
          fill={color || "#5f7beb"}
          width={size || 20}
          height={size || 20}
        />
      );
  }
};

export const IconByDimension = ({
  dimension,
  size,
  color,
}: {
  dimension: string;
  size: number;
  color: string;
}) => {
  return getIconByDimension(dimension, size, color);
};
