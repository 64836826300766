import React, { ReactNode, useEffect } from "react";
import ResponsiveHeader from "../responsive-header/ResponsiveHeader";
import Sidebar from "../sidebar/Sidebar";
import ReactGA from "react-ga";

export function DefaultLayout({ children }: { children?: ReactNode }) {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <div className="flex md:hidden">
        <ResponsiveHeader />
      </div>
      <div className="flex h-full flex-wrap">
        <div className="hidden md:flex">
          <Sidebar />
        </div>
        {children}
      </div>
    </>
  );
}
