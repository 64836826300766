import React from "react";
import PhysicalShape from "../../assets/png/card-physical-shape.png";
import SocialShape from "../../assets/png/card-social-shape.png";
import MentalShape from "../../assets/png/card-mental-shape.png";
import DimensionScore from "../dimension-score/DimensionScore";
import { ReactComponent as EmotionB } from "../../assets/svg/emotion-b.svg";
import { ReactComponent as EmotionH } from "../../assets/svg/emotion-h.svg";
import { ReactComponent as EmotionOK } from "../../assets/svg/emotion-ok.svg";
import { ReactComponent as EmotionVB } from "../../assets/svg/emotion-vb.svg";
import { ReactComponent as EmotionVH } from "../../assets/svg/emotion-vh.svg";
import { ReactComponent as MentalPicto } from "../../assets/svg/mental-picto.svg";
import { ReactComponent as SocialPicto } from "../../assets/svg/social-picto.svg";
import { ReactComponent as PhysicalPicto } from "../../assets/svg/physical-picto.svg";
import { getNameByDimension } from "../../utils/dimensions";
import { useCurrentUser } from "../../services/me";

const DimensionCardAccountInfos = ({
  dimension,
}: {
  dimension: "physical" | "mental" | "social";
}) => {
  const { data: user } = useCurrentUser();

  const renderEmotion = () => {
    const ceilScore = Math.round(user?.[`wellbeing_${dimension}_score`] ?? 0);

    if (ceilScore >= 85) {
      return <EmotionVH className="custom-rotate-15" />;
    }
    if (ceilScore >= 76) {
      return <EmotionH className="custom-rotate-minus-15" />;
    }
    if (ceilScore >= 52) {
      return <EmotionOK className="custom-rotate-8" />;
    }
    if (ceilScore >= 43) {
      return <EmotionB className="custom-rotate-12" />;
    }
    return <EmotionVB className="custom-rotate-minus-15" />;
  };

  const getPictoByDimension = () => {
    switch (dimension) {
      case "physical":
        return (
          <PhysicalPicto
            width={35}
            height={35}
            style={{ left: "14%" }}
            className="absolute top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
          />
        );
      case "mental":
        return (
          <MentalPicto
            width={35}
            height={35}
            className="absolute"
            style={{
              bottom: 14,
              left: "15%",
            }}
          />
        );
      case "social":
        return (
          <SocialPicto
            width={35}
            height={35}
            className="absolute"
            style={{
              top: 17,
              right: 34,
            }}
          />
        );
      default:
        return null;
    }
  };

  const getShapeByDimension = () => {
    switch (dimension) {
      case "physical":
        return PhysicalShape;
      case "social":
        return SocialShape;
      case "mental":
        return MentalShape;
      default:
        return PhysicalShape;
    }
  };

  return (
    <div
      className={`w-full rounded-3xl md:flex md:flex-wrap bg-custom-${dimension}Light mb-8 overflow-hidden`}
    >
      <div className="w-full p-6 md:w-1/2">
        <div className="flex flex-wrap items-center">
          <h1 className=" text-3xl font-bold">
            Dimension {getNameByDimension(dimension, true).toLowerCase()}
          </h1>
          {(!user || !user.first_name) && (
            <>
              <div
                className="mx-3 h-4 w-40 animate-pulse
                rounded-full bg-custom-lightGrey bg-opacity-60 transition-all hover:opacity-75"
              />
              <span className=" text-3xl font-bold"> ! ☀️️</span>
            </>
          )}
        </div>
        <p className="mt-4">
          Découvre les nouveaux contenus de cette dimension pour améliorer
          ton bien-être !
        </p>
      </div>
      <div
        style={{ minHeight: 250 }}
        className={`relative flex items-center justify-center md:w-1/2 md:bg-transparent bg-custom-${dimension}`}
      >
        <img
          alt="Shape"
          className="absolute right-0 top-0 z-0 hidden h-full w-full rotate-90 object-cover md:block"
          src={getShapeByDimension()}
        />
        <div className="z-10">
          <DimensionScore
            ratingWidth={150}
            ratingHeight={5.5}
            textSize="text-2xl"
            score={user?.[`wellbeing_${dimension}_score`] || 0}
          />
        </div>
        {getPictoByDimension()}
        <div className="absolute bottom-8 right-8 z-10 inline-block">
          {renderEmotion()}
        </div>
      </div>
    </div>
  );
};

export default DimensionCardAccountInfos;
