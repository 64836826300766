/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 *
 */

import axios, { AxiosError } from "axios";
import axiosRetry from "axios-retry";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosRetry(client, { retries: 5 });
axiosRetry(client, { retryDelay: (retryCount) => retryCount * 2000 });

function onError(error: AxiosError) {
  console.debug("Request Failed:", error.config); // eslint-disable-line no-console
  if (error.response) {
    console.debug("Status:", error.response.status); // eslint-disable-line no-console
    console.debug("Data:", error.response.data); // eslint-disable-line no-console
    console.debug("Headers:", error.response.headers); // eslint-disable-line no-console
  } else {
    console.debug("Error Message:", error.message); // eslint-disable-line no-console
  }
  return Promise.reject(error.response || error.message);
}

function request<TypeOfResult>(...params: Parameters<typeof client>) {
  return client(...params)
    .then(({ data }) => data as TypeOfResult)
    .catch(onError);
}

export default request;
