import React from "react";
import Rodal from "rodal";
import RoundedButton from "../rounded-button/RoundedButton";
import { ReactComponent as MowiSad } from "../../assets/svg/mowi-sad.svg";
import { ReactComponent as CrossCircle } from "../../assets/icons/cross-circle.svg";
import colors from "../../theme/colors";
import { deleteAccount } from "../../services/login";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCurrentUser } from "../../services/me";

const DeleteAccountModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const windowWidth = window.innerWidth;

  const { data: user } = useCurrentUser();

  function confirmDeleteAccount() {
    deleteAccount(user?.id);
    localStorage.removeItem("moha-at");
    localStorage.removeItem("moha-rt");
    navigate("/");
    toast.success(`Ton compte a été supprimé`);
  }

  return (
    <Rodal
      height={windowWidth < 728 ? 225 : 525}
      width={windowWidth < 728 ? 490 : 790}
      customStyles={{
        padding: 0,
        borderRadius: 25,
        right: windowWidth < 728 ? "45%" : "0%",
        bottom: windowWidth < 728 ? "30%" : "0%",
      }}
      showCloseButton={false}
      visible={isOpen}
      onClose={onClose}
    >
      <div>
        <div
          className="flex flex-grow items-center justify-center rounded-t-3xl"
          style={{
            height: 165,
            transition: "background-color 0.5s",
            backgroundColor: colors.yellow200,
          }}
        >
          <MowiSad />
          <CrossCircle
            onClick={onClose}
            className="absolute right-5 top-5 cursor-pointer"
          />
        </div>
        <div
          className="opacity-1 rounded-b-3xl p-8"
          style={{ backgroundColor: colors.beige }}
        >
          <p className="mb-7 text-center text-4xl">Tu nous quittes déjà ?</p>
          <div className="mb-9">
            <p className="text-center text-2xl">
              Es-tu sûr de vouloir supprimer définitivement
            </p>
            <p className="text-center text-2xl">ton compte Moha ?</p>
          </div>
          <p
            className="mb-12 text-center text-xl"
            style={{ color: colors.blueGrey }}
          >
            Cette action est irréversible !
          </p>
          <div className="flex">
            <RoundedButton
              borderColor={colors.navyLight}
              buttonColor={colors.beige}
              textColor={colors.navyLight}
              className="mr-11"
              label="Annuler"
              onPress={onClose}
            />
            <RoundedButton
              buttonColor={colors.danger400}
              label="Supprimer mon compte"
              onPress={confirmDeleteAccount}
            />
          </div>
        </div>
      </div>
    </Rodal>
  );
};

export default DeleteAccountModal;
