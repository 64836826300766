import { useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import CardContent from "../components/card-article/CardContent";
import { Link } from "react-router-dom";
import SeeMoreArrow from "../components/see-more-arrow/SeeMoreArrow";
import CardSkeletons from "../components/card-skeletons/CardSkeletons";
import replays from "../datas/replays";
import CardArticleLocalVideo from "../components/card-article-local-video/CardArticleLocalVideo";
import CardAccountInfos from "../components/card-account-infos/CardAccountInfos";
import ReactGA from "react-ga";
import ResponsiveHeader from "../components/responsive-header/ResponsiveHeader";
import { useQuery } from "@tanstack/react-query";
import request from "../utils/AxiosWrap";

const HomePage = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["HomeQuery"],
    queryFn: () => {
      const token = localStorage.getItem("moha-at");
      return request<{
        user: { id: number };
        contents: {
          id: number;
          cover?: string;
          title?: string;
          content_type?: string;
          time_to_read_in_seconds?: number;
          dimensions: { slug?: string };
          sub_dimensions: { name?: string };
        }[];
      }>("/api/v1/home", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    refetchOnWindowFocus: false,
  });

  const { user, contents } = data || { contents: [] };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <div className="flex md:hidden">
        <ResponsiveHeader />
      </div>
      <div className="flex h-full flex-wrap">
        <div className="z-10 hidden md:flex">
          <Sidebar />
        </div>
        <div className="flex-grow p-4 md:p-8 md:pl-2" style={{ flexBasis: 0 }}>
          <div>
            {user && Object.keys(user).length > 0 && <CardAccountInfos />}
            <div className="mb-8 flex flex-wrap items-center">
              <h1 className=" flex-grow text-2xl">Ma bibliothèque bien-être</h1>
            </div>
            <div className="2xl-grid:cols-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
              {contents.length === 0 && isLoading && (
                <CardSkeletons count={5} />
              )}
              {contents.map((content, i) => {
                return (
                  <Link
                    key={`HomeContentCard${i}`}
                    to={`/content/${content.id}`}
                  >
                    <CardContent content={content} />
                  </Link>
                );
              })}
            </div>
          </div>

          <div className="mt-12">
            <div className="mb-8 flex flex-wrap items-center">
              <h1 className=" flex-grow text-2xl">Mes replays</h1>
              <Link to="/replays">
                <SeeMoreArrow />
              </Link>
            </div>
            {replays.length === 0 && !isLoading && (
              <p className="text-custom-grey">
                Pas encore de lives à afficher. Ça arrive bientôt ! 😉
              </p>
            )}
            <div className="2xl-grid:cols-6 mb-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
              {isLoading && <CardSkeletons count={5} />}
              {!isLoading &&
                replays.map((e, index) => {
                  return (
                    <Link key={index} to={`/replay/${e.id}`}>
                      <CardArticleLocalVideo content={e} />
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
