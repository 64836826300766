import "./App.css";
import Login from "./routes/Login";
import {
  useLocation,
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { FC, useEffect } from "react";
import HomePage from "./routes/HomePage";
import DimensionHomePage from "./routes/DimensionHomePage";
import Content from "./routes/Content";
import ContentLibrary from "./routes/ContentLibrary";
import ReplaysLibrary from "./routes/Replays";
import { ToastContainer } from "react-toastify";
import ForgotPwd from "./routes/ForgotPwd";
import ResetPwd from "./routes/ResetPwd";
import ContentLocalVideo from "./routes/ContentLocalVideo";
import "rodal/lib/rodal.css";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AdminContentUpdate } from "./routes/admin/ContentUpdate";
import AdminContentLibrary from "./routes/admin/AdminContentLibrary";
import Test from "./routes/test";
import { useCurrentUser } from "./services/me";

const queryClient = new QueryClient();
try {
  ReactGA.initialize("G-TS2JL0JDB8", {
    gaOptions: {
      sampleRate: 100,
    },
  });
} catch (err) {}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const PrivateRoute = ({
  component: RouteComponent,
}: {
  component: FC;
}) => {
  const isAuthenticated = localStorage.getItem("moha-at");

  if (isAuthenticated) {
    return <RouteComponent />;
  }
  return <Navigate to="/" />;
};

export const LoginRoute = ({
  component: RouteComponent,
}: {
  component: React.FC;
}) => {
  const isAuthenticated = localStorage.getItem("moha-at");

  if (!isAuthenticated) {
    return <RouteComponent />;
  }
  return <Navigate to="/home" />;
};

function UserFetcher({ children }: { children: React.ReactNode }) {
  const { refetch } = useCurrentUser();

  useEffect(() => {
    const token = localStorage.getItem("moha-at");

    if (token) {
      refetch();
    }
  }, [refetch]);
  return <>{children}</>;
}

function App() {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <UserFetcher>
          <Router>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<LoginRoute component={Login} />} />
              <Route
                path="/forgotpassword"
                element={<LoginRoute component={ForgotPwd} />}
              />
              <Route
                path="/resetpassword"
                element={<LoginRoute component={ResetPwd} />}
              />
              <Route
                path="/home"
                element={<PrivateRoute component={HomePage} />}
              />
              <Route
                path="/content/:id"
                element={<PrivateRoute component={Content} />}
              />
              <Route
                path="/replay/:id"
                element={<PrivateRoute component={ContentLocalVideo} />}
              />
              <Route
                path="/library"
                element={<PrivateRoute component={ContentLibrary} />}
              />
              <Route
                path="/replays"
                element={<PrivateRoute component={ReplaysLibrary} />}
              />
              <Route
                path="/dimension/:dimension"
                element={<PrivateRoute component={DimensionHomePage} />}
              />
              <Route
                path="/admin/content/update/:id"
                element={<PrivateRoute component={AdminContentUpdate} />}
              />
              <Route
                path="/admin/library"
                element={<PrivateRoute component={AdminContentLibrary} />}
              />
              <Route
                path="/test/secret/demo"
                element={<PrivateRoute component={Test} />}
              />
              <Route
                path="*"
                element={<PrivateRoute component={ReplaysLibrary} />}
              />
            </Routes>
          </Router>
        </UserFetcher>
      </QueryClientProvider>
      <ToastContainer theme="colored" />
    </div>
  );
}

export default App;
