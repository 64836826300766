import React, { useEffect, useState, ReactNode } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link, useSearchParams } from "react-router-dom";
import CardSkeletons from "../../components/card-skeletons/CardSkeletons";
import { getIconByDimension } from "../../utils/dimensions";
import colors from "../../theme/colors";
import ReactGA from "react-ga";
import ResponsiveHeader from "../../components/responsive-header/ResponsiveHeader";
import { useQuery } from "@tanstack/react-query";
import { listAdminContents } from "../../services/adminContent";
import { IconByArticleType } from "../../utils/articles";

//todo : get rid of moment
import moment from "moment";
import "moment/locale/fr";
import RoundedButton from "../../components/rounded-button/RoundedButton";
moment.locale("fr");

const AdminContentLibrary = () => {
  const [searchParams] = useSearchParams();

  const [tab, setTab] = useState(
    searchParams.get("dimension") ? searchParams.get("dimension") : "all"
  );

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const { data: contents } = useQuery({
    queryKey: ["listAdminContentsQuery", tab],
    queryFn: () => listAdminContents(tab),
    refetchInterval: false,
  });

  return (
    <>
      <div className="flex md:hidden">
        <ResponsiveHeader />
      </div>
      <div className="flex h-full flex-wrap">
        <div className="hidden md:flex">
          <Sidebar />
        </div>
        <div className="flex-grow p-8 md:pl-2" style={{ flexBasis: 0 }}>
          <div>
            <div className="flex flex-row justify-between">
              <div>
                <div className="flex items-center">
                  <h1 className=" flex-grow text-2xl">
                    La bibliothèque bien-être par Moha
                  </h1>
                </div>
                <h1 className="text-md flex-grow text-custom-grey">
                  Retrouve tous les contenus en libre accès qui te permettent de
                  prendre soin de toi au quotidien
                </h1>
                <div className="mt-6 mb-8 flex flex-wrap items-center">
                  <LibraryTab
                    isSelected={tab === "all"}
                    onPress={() => setTab("all")}
                    label="Tous"
                    className="mr-4 bg-custom-yellow"
                  />
                  <LibraryTab
                    icon={getIconByDimension("physical", 15, colors.beige)}
                    isSelected={tab === "physical"}
                    onPress={() => setTab("physical")}
                    label="Physique"
                    className="mr-4 bg-custom-physical"
                  />
                  <LibraryTab
                    icon={getIconByDimension("mental", 15, colors.beige)}
                    isSelected={tab === "mental"}
                    onPress={() => setTab("mental")}
                    label="Mental"
                    className="mr-4 bg-custom-mental"
                  />
                  <LibraryTab
                    icon={getIconByDimension("social", 15, colors.beige)}
                    isSelected={tab === "social"}
                    onPress={() => setTab("social")}
                    label="Relationnel"
                    className="bg-custom-social"
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <Link to={`/admin/content/update/new`} className="ml-3">
                  <RoundedButton
                    label="Nouveau contenu"
                    onPress={() => undefined}
                    width="w-32"
                    className="h-32"
                  />
                </Link>
              </div>
            </div>
            <div className="2xl-grid:cols-6 h- grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
              <DisplayContents contents={contents} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

type LibraryTabProps = {
  className: string;
  label: string;
  onPress?: () => void;
  isSelected: boolean;
  icon?: ReactNode;
};

function LibraryTab({
  className,
  label,
  onPress,
  isSelected,
  icon,
}: LibraryTabProps) {
  return (
    <div
      onClick={onPress}
      className={`inline-block cursor-pointer rounded-xl px-5 py-2 transition-all hover:opacity-100 ${
        isSelected ? "opacity-100" : "opacity-50"
      } ${className}`}
    >
      <div className="flex flex-wrap items-center">
        {icon}
        <p className={`text-custom-beige  ${icon ? "ml-2" : ""}`}>{label}</p>
      </div>
    </div>
  );
}

function DisplayContents({
  contents,
}: {
  contents?: Awaited<ReturnType<typeof listAdminContents>>;
}) {
  if (!contents || contents.length < 1) return <CardSkeletons count={20} />;

  return (
    <>
      {contents.map((content, i) => (
        <DisplaySingleContent
          key={`displayAdminContentList${i}`}
          content={content}
        />
      ))}
    </>
  );
}

const availableStatus = [
  { text: "Redaction", textColor: "text-custom-yellow" },
  { text: "Prêt", textColor: "text-custom-mental" },
];

function DisplaySingleContent({
  content,
}: {
  content: Awaited<ReturnType<typeof listAdminContents>>[0];
}) {
  const status = availableStatus[content.status ?? 0];

  const date = moment(content.updated_at).format("D MMMM YY");

  return (
    <Link to={`/admin/content/update/${content.id}`}>
      <div
        style={{ minHeight: 165 }}
        className="flex h-full w-full grow cursor-pointer flex-col overflow-hidden rounded-3xl bg-white shadow-lg transition-all"
      >
        <img
          className="h-28 w-full flex-shrink object-cover"
          alt={"Article"}
          src={content?.cover || ""}
        />
        <div className="flex h-full flex-grow flex-col p-3">
          <p className=" h-full line-clamp-2">{content?.title || ""}</p>
          <div className="mt-5 flex items-center justify-between">
            <div
              className={`flex flex-wrap items-center bg-custom-${content?.dimensions?.slug} rounded-lg px-2 py-1`}
            >
              <p className="text-xs text-custom-beige">
                {content?.sub_dimensions?.name?.toLowerCase() ?? ""}
              </p>
            </div>
            <div className="flex flex-wrap items-center">
              <IconByArticleType type={content?.content_type} size={14} />
              <p className=" ml-1 text-xs text-custom-grey">
                {typeof content?.time_to_read_in_seconds === "number"
                  ? Math.ceil(content?.time_to_read_in_seconds / 60)
                  : 0}{" "}
                min
              </p>
            </div>
          </div>
          <div className="mt-5 flex items-center justify-between">
            <p className={`${status.textColor} text-xs`}>{status.text}</p>
            <p className={`text-xs text-custom-grey`}>
              {content.last_edited_by_user?.first_name ?? ""}
            </p>
            <p className={`text-xs text-custom-grey`}>{date}</p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default AdminContentLibrary;
