import React from "react";

type DimensionScoreProps = {
  score: number;
  ratingWidth?: number;
  ratingHeight?: number;
  textSize?: string;
  className?: string;
  style?: React.CSSProperties;
};

const DimensionScore = ({
  score,
  ratingWidth,
  ratingHeight,
  textSize,
  className,
  style,
}: DimensionScoreProps) => {
  const ratingLine = () => {
    let item = [];
    for (let i = 1; i <= 5; i++) {
      item.push(
        <div
          key={i}
          style={{
            height: ratingHeight || 4,
            borderBottomLeftRadius: i === 1 ? 10 : 0,
            borderBottomRightRadius: i === 5 ? 10 : 0,
            opacity: i <= Math.round(score / 20) ? 1 : 0.5,
            backgroundColor: "white",
            flex: 1,
            marginLeft: 0.5,
            marginRight: 0.5,
          }}
        />
      );
    }
    return item;
  };

  return (
    <div className={className} style={style}>
      <div>
        <p
          className={`text-xs text-custom-beige ${
            textSize ? textSize : "md:text-sm"
          } mb-2 text-center`}
        >
          {Math.round(score)}/100
        </p>
      </div>
      <div className="flex flex-wrap" style={{ width: ratingWidth || 75 }}>
        {ratingLine()}
      </div>
    </div>
  );
};

export default DimensionScore;
