import request from "../utils/AxiosWrap";

export function fetchNextFormToFill() {
  const token = localStorage.getItem("moha-at");

  return request<{ form: string }>("/api/v1/next_form_to_fill", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export type Evaluation = {
  id: number;
  status: string;
  form_type: string;
  estimate_in_seconds: number;
  slug?: string;
  questions: {
    id: number;
    question_type?: string;
    range_min?: number;
    range_max?: number;
    dimension_id: number;
    slug?: string;
    inversion: boolean;
    question_indication_id?: number;
    text?: string;
    description?: string;
    sub_dimension: {
      id: number;
      slug?: string;
      icon?: string;
      name?: string;
    };
    dimension: {
      id: number;
      slug?: string;
      icon?: string;
      name?: string;
    };
    question_indication: {
      id: number;
      min_indication?: string;
      max_indication?: string;
      slug?: string;
    };
    sub_question: [];
    position: number;
  }[];
  user_wellbeing_form_id: number;
};

export function fetchDailyEvaluation() {
  const token = localStorage.getItem("moha-at");

  return request<Evaluation>("/api/v2/wellbeing_forms/daily", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function postAnswer(form: {
  userFormId: number;
  questionId: number;
  answer: { value: number; start_initial_score?: boolean };
}) {
  const token = localStorage.getItem("moha-at");

  return request(
    `/api/v2/user_wellbeing_forms/${form.userFormId}/questions/${form.questionId}/answers`,
    {
      method: "POST",
      data: form.answer,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export const formServices = {
  fetchNextFormToFill,
  fetchDailyEvaluation,
  postAnswer,
};
