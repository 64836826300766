import React from "react";

function CardSkeletons({ count }: { count: number }) {
  const rightSizedArray = [...new Array(count)];
  return (
    <>
      {rightSizedArray.map((_, index) => {
        return (
          <div
            key={index}
            className="animate-pulse cursor-pointer rounded-3xl bg-gray-200 bg-opacity-60 p-4 transition-all hover:opacity-75"
          >
            <div className="h-32" />
          </div>
        );
      })}
    </>
  );
}

export default CardSkeletons;
