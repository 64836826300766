import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  CircleButton,
} from "../components/buttons";
import { ArrowRightCircleButton } from "../components/buttons";
import { GoBackButton } from "../components/buttons";

const Test = () => {
  return (
    <div className="grid grid-cols-4">
      <PrimaryButton>Label</PrimaryButton>
      <PrimaryButton disabled>Label</PrimaryButton>
      <PrimaryButton color="yellow">Label</PrimaryButton>
      <PrimaryButton color="yellow" disabled>
        Label
      </PrimaryButton>
      <SecondaryButton>Label</SecondaryButton>
      <SecondaryButton disabled>Label</SecondaryButton>
      <SecondaryButton color="yellow">Label</SecondaryButton>
      <SecondaryButton color="yellow" disabled>
        Label
      </SecondaryButton>

      <CircleButton>Label</CircleButton>
      <CircleButton disabled>Label</CircleButton>
      <CircleButton color="yellow">Label</CircleButton>
      <CircleButton color="yellow" disabled>
        Label
      </CircleButton>
      <CircleButton mohaType="secondary">Label</CircleButton>
      <CircleButton mohaType="secondary" disabled>
        Label
      </CircleButton>
      <CircleButton mohaType="secondary" color="yellow">
        Label
      </CircleButton>
      <CircleButton mohaType="secondary" color="yellow" disabled>
        Label
      </CircleButton>
      <TertiaryButton>Label</TertiaryButton>
      <GoBackButton />
      <ArrowRightCircleButton />
      <ArrowRightCircleButton color={"yellow"} />
    </div>
  );
};

export default Test;
