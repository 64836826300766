import React, { useState } from "react";
import { ReactComponent as Show } from "../../assets/icons/show.svg";
import { ReactComponent as Hide } from "../../assets/icons/hide.svg";
import colors from "../../theme/colors";

type InputProps = {
  showEye?: boolean;
  type: string;
  mandatory?: boolean;
  placeholder: string;
  classes: string;
  label: string;
  setForm: (
    action: (data: { [key: string]: string }) => { [key: string]: string }
  ) => void;
  name: string;
  value: string | number | readonly string[] | undefined;
};

function Input({
  showEye,
  type,
  mandatory,
  placeholder,
  classes,
  label,
  setForm,
  name,
  value,
}: InputProps) {
  const [isVisible, setIsVisible] = useState(!(type === "password"));

  const getInputType = () => {
    if (type === "password" && !isVisible) {
      return "password";
    } else if (type === "password" && isVisible) {
      return "text";
    }
    return type || "text";
  };

  return (
    <div className={classes}>
      <p className="mb-2 text-lg ">
        {label}
        <span className={`${mandatory ? "visible" : "hidden"} text-red-600`}>
          *
        </span>
      </p>
      <div className="relative">
        {(!type || type !== "textarea") && (
          <input
            type={getInputType()}
            className="w-full rounded-lg border px-4 py-2 placeholder-custom-placeholder"
            onChange={(evt) =>
              setForm((e) => {
                const copy = { ...e };
                copy[name] = evt.target.value;
                return copy;
              })
            }
            value={value}
            name={name}
            placeholder={placeholder || label || ""}
          />
        )}
        {type === "textarea" && (
          <textarea
            className="bg-custom-bg border-custom-greySeparator placeholder-custom-greyLight w-full rounded-lg border px-4 py-2"
            onChange={(evt) =>
              setForm((e) => {
                const copy = { ...e };
                copy[name] = evt.target.value;
                return copy;
              })
            }
            value={value}
            name={name}
            placeholder={placeholder || label || ""}
          />
        )}
        {showEye && (
          <div
            onClick={() => setIsVisible((e) => !e)}
            className="absolute mr-3 flex h-full cursor-pointer items-center
                           justify-center transition-all hover:opacity-70"
            style={{ right: 0, top: 0 }}
          >
            {isVisible ? (
              <Show fill={colors.grey} />
            ) : (
              <Hide fill={colors.grey} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

type ReactInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export function TextInput({
  className,
  label,
  required = false,
  placeholder,
  children,
  ...props
}: ReactInputProps & { label: string }) {
  return (
    <div className={className}>
      <p className="mb-2 text-lg ">
        {label}
        <span className={`${required ? "visible" : "hidden"} text-red-600`}>
          *
        </span>
      </p>
      <div className="relative">
        <input
          className="bg-custom-bg w-full rounded-lg border px-4 py-2 placeholder-custom-placeholder"
          placeholder={placeholder || label || ""}
          required={required}
          {...props}
        />
        {children}
      </div>
    </div>
  );
}

export function PasswordInput({
  type: _,
  ...props
}: ReactInputProps & { label: string; required?: boolean }) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <TextInput {...props} type={isVisible ? "text" : "password"}>
      <div
        onClick={() => setIsVisible((e) => !e)}
        className="absolute mr-3 flex h-full cursor-pointer items-center
                           justify-center transition-all hover:opacity-70"
        style={{ right: 0, top: 0 }}
      >
        {isVisible ? <Show fill={colors.grey} /> : <Hide fill={colors.grey} />}
      </div>
    </TextInput>
  );
}

export default Input;
